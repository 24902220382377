import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalTitle } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';

import { IconSettings, IconPolygon, IconLink, IconRectangle, IconDimensions, IconAngle, IconPolygonOff, IconEyeOff, IconEye, IconSettingsShare, IconShare3, IconRestore, IconRobot, IconHighlight, IconTrashX, IconCopy, IconFolderOff } from '@tabler/icons-react';
import { UOM_Dependencies, UOM_Display, UOM_Measured_Default, calculateValue } from '../../helper/UnitConversions';
import { MeasurementSideBarContext, TakeoffContext } from '../../helper/Context';
import { selectAuth } from '../../../redux/slices/authSlice';

import { API_ROUTE } from '../../../index';

import GroupMenu from './GroupMenu';
import DefaultButton from '../../../components/DefaultButton';
import ButtonHoverInfo from '../../../components/ButtonHoverInfo';
import DeleteButton from '../../../components/DeleteButton';
import AutoResizeTextInput from '../../../components/AutoResizeTextInput';
import HoverDelay from '../../../components/HoverDelay';
import ColorInput from '../../../components/ColorInput';
import ColorPicker from '../../../components/ColorPicker';
import DefaultIconButton from '../../../components/DefaultIconButton';
import Popup from 'reactjs-popup';

export default function PolygonMeasurement({ measurement, showModal, setShowModal }) {
    const {
        project,
        pageID,
        pages, setPages,
        groups, setGroups,
        measurements, setMeasurements,
        currentMeasurement, setCurrentMeasurement,
        editingMeasurement, setEditingMeasurement,
        handleZoomToMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        handleClickMeasurement,
        handlePolygonTransform,
        groupMeasurement,
        setShowDetails,
        filterPages, setFilterPages,
        filterArea, setFilterArea,
        filterVolume, setFilterVolume,
        cuttingPolygon, splittingPolygon,
        search,
        optionDown,
        drawing,
        takeoffSettings,
        setShowDeleteMeasurementModal,
        handleDuplicateMeasurement,
    } = useContext(TakeoffContext);

    const {
        sidebarWidth,
        setHoveringGroup,
    } = useContext(MeasurementSideBarContext);

    const [showGear, setShowGear] = useState(false);
    const [editingName, setEditingName] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useState(measurement?.color);

    const [measurementName, setMeasurementName] = useState(measurement?.name || '');

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                if (!cuttingPolygon && !splittingPolygon) {
                    if (editingName || editingMeasurement) {
                        setEditingName(false);
                        setEditingMeasurement(false);
                        handlePolygonTransform(measurement);
                    } else if (currentMeasurement === measurement.id && !showModal && !drawing) {
                        e.stopPropagation();
                        e.stopImmediatePropagation();
                        e.preventDefault();
                        setEditingName(true);
                    }
                }
            } else if (e.key === 'Escape' && document.activeElement.tagName === 'INPUT') {
                e.stopPropagation();
                e.preventDefault();

                document.activeElement.blur();
                setEditingName(false);
                setEditingMeasurement(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [editingName, editingMeasurement, currentMeasurement, measurement, showModal, cuttingPolygon, splittingPolygon]);

    useEffect(() => {
        if (measurementName !== measurement?.name) {
            setMeasurementName(measurement?.name);
        }
    }, [measurement?.name]);

    const getClassName = () => {
        let className = 'measurementsidebar-group-measurement';
        if (measurements[currentMeasurement]?.id === measurement.id) {
            className += ' measurementsidebar-group-measurement-selected';
        } else if (measurement?.page != pageID || measurement?.hide || measurement?.group && groups[measurement.group]?.hide) {
            className += ' measurementsidebar-group-measurement-differentpage';
        }

        if (selectedMeasurements.find((m) => m === measurement.id)) {
            className += ' measurementsidebar-group-measurement-multiselected';
        }
        return className;
    }

    return (
        <>
            {/*<OptionModal
                measurement={measurement}
                showModal={showModal}
                setShowModal={setShowModal}
                measurementName={measurementName}
                setMeasurementName={setMeasurementName}
    />*/}
            <div
                id={'measurement-' + measurement.id}
                className={getClassName()}
                style={{
                    width: (sidebarWidth ? sidebarWidth : 0.20 * window.innerWidth) - (measurements[measurement.id]?.path?.split('/').length - 2) * 20 - 15 + 'px',
                }}
                /*onClick={(e) => {
                    setCurrentMeasurement(measurement.id);
                    handleClickMeasurement(e, measurement);
                    setShowDetails(true);
                }}*/
                onMouseEnter={() => {
                    if (measurement.group) {
                        setHoveringGroup('group-' + measurement.group);
                    }
                    setShowGear(true)
                }}
                onMouseLeave={() => {
                    setHoveringGroup(null);
                    setShowGear(false)
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowModal(true);
                    //setEditingMeasurement(true);
                }}
                onDoubleClick={() => {
                    if (measurement.page != pageID) {
                        window.history.pushState({}, '', `/takeoff/${project.uuid}/${measurement.page}`);
                    }

                    if (measurement?.polygon_dots?.length > 0) {
                        handleZoomToMeasurement();
                    }
                }}
            >
                <div className='measurementsidebar-measurement-icon'
                    style={{
                        width: (sidebarWidth ? sidebarWidth : 0.20 * window.innerWidth) * 0.5,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '32px',
                            paddingLeft: '8px',
                            paddingRight: measurement?.ai_created ? '0px' : '8px',
                        }}
                    >
                        <IconPolygon size={16} color={measurement?.color} stroke={1.5} />
                    </div>

                    {measurement?.ai_created &&
                        <div
                            style={{
                                width: '20px',
                                marginRight: '8px',
                            }}
                        >
                            <IconRobot
                                id={'measurement-icon-' + measurement.id + '-count-ai-badge'}
                                size={20} color={measurement?.color} stroke={1}
                                style={{
                                    outline: 'none',
                                }}
                            />
                            <Tooltip className='tooltip' anchorSelect={'#measurement-icon-' + measurement.id + '-count-ai-badge'} delayShow={500}>
                                AI created area
                            </Tooltip>
                        </div>
                    }

                    {editingName
                        ? <input
                            type='text'
                            className='measurementsidebar-measurement-name-input'
                            style={{
                                width: (sidebarWidth ? sidebarWidth : 0.20 * window.innerWidth) - (measurement?.path?.split('/').length - 2) * 20 - 32 - (measurement.ai_created ? 28 : 0) - 102 + 'px',
                            }}
                            value={measurementName}
                            autoFocus
                            placeholder='Name...'
                            onChange={(e) => setMeasurementName(e.target.value)}
                            onBlur={() => {
                                handlePolygonTransform({
                                    ...measurement,
                                    name: measurementName,
                                });
                                setEditingName(false);
                                setEditingMeasurement(false);
                            }}
                        />
                        : <div
                            className={'measurementsidebar-measurement-name'}
                            id={`measurement-${measurement.id}-name`}
                            onClick={(e) => {
                                if (optionDown || e.altKey) {
                                    setEditingName(true);
                                }
                            }}
                            style={{
                                width: (sidebarWidth ? sidebarWidth : 0.20 * window.innerWidth) - (measurement?.path?.split('/').length - 2) * 20 - 32 - (measurement.ai_created ? 28 : 0) - 102 + 'px',
                            }}
                        >
                            {measurementName
                                ? measurementName
                                : measurement?.depth
                                    ? (measurement?.number ? `${measurement.number} ` : '') + 'Volume'
                                    : (measurement?.number ? `${measurement.number} ` : '') + 'Area'
                            }
                        </div>
                    }
                </div>

                {measurementName?.length > 20 &&
                    <Tooltip
                        className='tooltip'
                        anchorSelect={'#measurement-' + measurement.id + '-name'}
                        delayShow={500}
                        positionStrategy='fixed'
                        style={{ zIndex: 99999 }}
                        place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'right' : 'left'}
                    >
                        {measurementName}
                    </Tooltip>
                }

                <div className='measurementsidebar-measurement-details'
                /*style={{
                    width: (sidebarWidth ? sidebarWidth : 0.18 * window.innerWidth) * 0.40 - (measurement.path.replace(/[^-]/g, "").length + 1) * 6,
                    minWidth: (sidebarWidth ? sidebarWidth : 0.18 * window.innerWidth) * 0.40 - (measurement.path.replace(/[^-]/g, "").length + 1) * 6,
                }}*/
                >
                    <div className='measurementsidebar-measurement-details-value'>
                        {pages[measurement.page]?.scale
                            ? <nobr>
                                {calculateValue(measurement, pages[measurement.page]?.scale).toFixed(measurement.uom === 'ea' ? 0 : 1)} {UOM_Display[measurement.uom || UOM_Measured_Default[measurement.type]]}
                            </nobr>
                            : <nobr>No scale</nobr>
                        }
                    </div>

                    <Popup
                        trigger={
                            <div className='measurementsidebar-measurement-details-icons'>
                                {(showGear || measurement.hide) && <>
                                    {measurement.page != pageID
                                        ? <>
                                            <div
                                                className='measurementsidebar-measurement-hide'
                                                id={'measurement-' + measurement.id + '-differentpage'}
                                                onClick={() => {
                                                    setCurrentMeasurement(measurement.id);
                                                    window.history.pushState({}, '', `/takeoff/${project.uuid}/${measurement.page}`);
                                                    handleZoomToMeasurement(measurement.id);
                                                }}
                                                size={'small'}
                                            >
                                                <IconShare3 size={16} stroke={1} />
                                            </div>

                                            <Tooltip className='tooltip' anchorSelect={'#measurement-' + measurement.id + '-differentpage'} delayShow={500}>
                                                Measuremenet from different page ({pages[measurement.page].title})
                                            </Tooltip>
                                        </>
                                        : <>
                                            <div
                                                className='measurementsidebar-measurement-hide'
                                                id={'measurement-hide-' + measurement.id}
                                                style={{ visibility: (measurement?.hide || showGear) ? 'visible' : 'hidden' }}
                                                //visibility={measurement?.hide || showGear}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    if (e.altKey) {
                                                        let newMeasurements = { ...measurements };

                                                        if (Object.values(newMeasurements).reduce((acc, m) => {
                                                            if (!m.hide) {
                                                                return acc + 1;
                                                            }
                                                            return acc;
                                                        }, 0) > 1) {
                                                            Object.values(newMeasurements).forEach((m) => {
                                                                newMeasurements[m.id].hide = true;
                                                            })
                                                            newMeasurements[measurement.id].hide = false;
                                                            setMeasurements(newMeasurements);
                                                        } else {
                                                            Object.values(newMeasurements).forEach((m) => {
                                                                newMeasurements[m.id].hide = false;
                                                            })
                                                            newMeasurements[measurement.id].hide = false;
                                                            setMeasurements(newMeasurements);
                                                        }
                                                    } else {
                                                        handlePolygonTransform({
                                                            ...measurement,
                                                            hide: !measurement.hide,
                                                        });
                                                    }
                                                }}
                                                size={'small'}
                                            >
                                                {measurement?.hide
                                                    ? <IconEyeOff size={16} stroke={1} />
                                                    : <IconEye size={16} stroke={1} />
                                                }
                                            </div>

                                            <Tooltip className='tooltip' anchorSelect={'#measurement-hide-' + measurement.id} delayShow={500} place='left-end'>
                                                <div>
                                                    <b>{measurement?.hide ? 'Show measurement' : 'Hide measurement'}</b>
                                                </div>
                                                {!measurement?.hide &&
                                                    <div>
                                                        Alt (Option) + click to toggle all other measurements
                                                    </div>
                                                }
                                            </Tooltip>
                                        </>
                                    }

                                    {/*<>
                                        <div
                                            className='measurementsidebar-measurement-hide'
                                            id={'measurement-' + measurement.id + '-gear'}
                                            style={{
                                                visibility: showGear ? 'visible' : 'hidden',
                                            }}
                                            onClick={() => {
                                                setShowModal(true);
                                                setEditingMeasurement(true);
                                            }}
                                            size={'small'}
                                        >
                                            <IconSettings size={16} stroke={1} />
                                        </div>
        
                                        <Tooltip className='tooltip' anchorSelect={'#measurement-' + measurement.id + '-gear'} delayShow={500}>
                                            Measurement settings
                                        </Tooltip>
                                    </>*/}
                                </>}
                            </div>
                        }
                        open={showModal}
                        on=''
                        onClose={() => {
                            setShowModal(false);
                            setShowColorPicker(false);
                        }}
                        position="bottom right"
                        closeOnDocumentClick
                        mouseLeaveDelay={300}
                        mouseEnterDelay={0}
                        arrow={false}
                    >
                        <div className="aisidebar-count-legend-settings">
                            <div className='aisidebar-count-legend-settings-group'>
                                <div className='aisidebar-count-legend-settings-group-body'>
                                    <div
                                        className='aisidebar-count-legend-settings-group-item'
                                        onClick={(e) => handlePolygonTransform({
                                            ...measurement,
                                            hide: !measurement.hide,
                                        })}
                                    >
                                        {measurement.hide
                                            ? <IconEyeOff size={20} stroke={1} />
                                            : <IconEye size={20} stroke={1} />
                                        } Visibility
                                    </div>

                                    <div
                                        className='aisidebar-count-legend-settings-group-item'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowColorPicker(true);
                                        }}
                                    >
                                        <div
                                            className='measurementsidebar-option-color-placeholder'
                                            style={{
                                                backgroundColor: color,
                                            }}
                                        ></div>

                                        Color
                                    </div>

                                    {showColorPicker
                                        ? <div
                                            style={{
                                                position: 'relative',
                                                top: '3px',
                                                left: '-50px',
                                                zIndex: 100,
                                            }}
                                        >
                                            <ColorPicker
                                                color={color}
                                                onChange={(color) => setColor(color.hex)}
                                                onChangeComplete={(color) => {
                                                    setColor(color.hex);
                                                    handlePolygonTransform({
                                                        ...measurement,
                                                        color: color.hex,
                                                        use_group_color: false,
                                                    });
                                                }}
                                            />
                                        </div>
                                        : null}

                                    {!measurement.use_group_color &&
                                        <div
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handlePolygonTransform({
                                                    ...measurement,
                                                    use_group_color: true,
                                                    color: groups[measurement.group]?.color || "#9DD9f3",
                                                })
                                            }}
                                        >
                                            <IconRestore size={20} stroke={1} />
                                            Use group color
                                        </div>
                                    }

                                    {measurement.group &&
                                        <div
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={() => {
                                                groupMeasurement({
                                                    id: measurement.id,
                                                    group: null,
                                                })
                                                setShowModal(false);
                                            }}
                                        >
                                            <IconFolderOff size={20} stroke={1} /> Ungroup
                                        </div>
                                    }

                                    <div
                                        className='aisidebar-count-legend-settings-group-item'
                                        onClick={() => {
                                            setEditingName(true);
                                            setShowModal(false);
                                        }}
                                    >
                                        <IconHighlight size={20} stroke={1} /> Rename
                                    </div>

                                    <div
                                        className='aisidebar-count-legend-settings-group-item'
                                        onClick={() => {
                                            handleDuplicateMeasurement(measurement);
                                            setShowModal(false);
                                        }}
                                    >
                                        <IconCopy size={20} stroke={1} /> Duplicate
                                    </div>
                                </div>
                            </div>

                            <hr className='aisidebar-count-legend-settings-hr'></hr>

                            <div className='aisidebar-count-legend-settings-group'>
                                <div className='aisidebar-count-legend-settings-group-body'>
                                    <div
                                        className='aisidebar-count-legend-settings-group-item-delete'
                                        onClick={(e) => {
                                            setCurrentMeasurement(measurement.id);
                                            setSelectedMeasurements([measurement.id]);
                                            setShowDeleteMeasurementModal(true);
                                            setShowModal(false);
                                        }}
                                    >
                                        <IconTrashX size={20} stroke={1} style={{ color: 'red' }} /> Delete
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popup>
                </div>
            </div>
        </>
    )
}

function OptionModal({
    measurement,
    showModal, setShowModal,
    measurementName, setMeasurementName,
}) {
    const auth = useSelector(selectAuth);

    const {
        project,
        pageID,
        pages, setPages,
        groups, setGroups,
        DeleteMeasurement,
        currentMeasurement, setCurrentMeasurement,
        handlePolygonTransform,
        groupMeasurement,
        handleZoomToMeasurement,
        measurements, setMeasurements,
        setSelectedMeasurements, setShowDeleteMeasurementModal,
        showDeleteMeasurementModal,
        setEditingMeasurement,
    } = useContext(TakeoffContext);

    const {
        createGroup,
    } = useContext(MeasurementSideBarContext);

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [newGroupName, setNewGroupName] = useState('');

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (showModal) {
                if (e.key === 'Enter' && !e.defaultPrevented) {
                    e.preventDefault();

                    document.activeElement.blur();
                    setShowColorPicker(false);
                } else if (e.key === 'Escape') {
                    e.stopPropagation();
                    e.preventDefault();

                    document.activeElement.blur();
                    setShowColorPicker(false);

                    if (showDeleteMeasurementModal) {
                        setShowDeleteMeasurementModal(false);
                    }
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [showModal, showDeleteMeasurementModal]);

    return (
        <Modal
            show={showModal}
            onHide={() => {
                if (showColorPicker) {
                    setShowColorPicker(false);
                } else {
                    setEditingMeasurement(false);
                    setShowModal(false);
                }
            }}
            dialogClassName='measurementsidebar-option-modal'
            onClick={() => setShowColorPicker(false)}
        >
            <Modal.Header closeButton>
                <div className='measurementsidebar-option-modal-title'>
                    Polygon Options
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='measurementsidebar-option-modal-body'>
                    {measurement.page != pageID &&
                        <div className='measurementsidebar-option-modal-section'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                From different page
                            </div>

                            <div>
                                <DefaultButton
                                    text={'Go to page ' + pages[measurement.page].title}
                                    handleClick={() => {
                                        setCurrentMeasurement(measurement.id);
                                        window.history.pushState({}, '', `/takeoff/${project.uuid}/${measurement.page}`);
                                        setShowModal(false);
                                        handleZoomToMeasurement(measurement.id);
                                    }}
                                    size={'small'}
                                />
                            </div>
                        </div>
                    }

                    <div className='measurementsidebar-option-modal-split-section'>
                        <div className='measurementsidebar-option-modal-section-row'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Visibility
                            </div>

                            <div className='measurementsidebar-option-color-container'>
                                <DefaultIconButton
                                    handleClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        handlePolygonTransform({
                                            ...measurement,
                                            hide: !measurement.hide,
                                        }, null, true);
                                    }}
                                >
                                    {measurement?.hide
                                        ? <IconEyeOff size={15} stroke={1} />
                                        : <IconEye size={15} stroke={1} />
                                    }
                                </DefaultIconButton>
                            </div>
                        </div>

                        <div className='measurementsidebar-option-modal-section-row'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Color
                            </div>

                            {!measurement?.use_group_color &&
                                <ButtonHoverInfo
                                    message={'Use group\'s color.'}
                                    top={20}
                                    left={0}
                                    size={'small'}
                                >
                                    <DefaultIconButton
                                        handleClick={() => {
                                            let colorTemp = groups[measurement.group]?.color ? groups[measurement.group].color : '#9DD9F3';

                                            handlePolygonTransform({
                                                ...measurement,
                                                use_group_color: true,
                                                color: colorTemp,
                                            }, null, true);
                                        }}
                                        size={'small'}
                                    >
                                        <IconRestore size={15} stroke={1} />
                                    </DefaultIconButton>
                                </ButtonHoverInfo>
                            }

                            <div
                                style={{
                                    position: 'relative',
                                    zIndex: 100,
                                }}
                            >
                                {showColorPicker
                                    ? <ColorPicker
                                        color={measurement.color}
                                        onChange={(color) => setMeasurements(prev => ({
                                            ...prev,
                                            [measurement.id]: {
                                                ...prev[measurement.id],
                                                color: color.hex,
                                                use_group_color: false,
                                            }
                                        }))}
                                        onChangeComplete={(color) => {
                                            handlePolygonTransform({
                                                ...measurement,
                                                color: color.hex,
                                                use_group_color: false,
                                            }, null, true);
                                        }}
                                    />
                                    : null}

                                <ButtonHoverInfo
                                    message={measurement?.use_group_color ? `Currently using group's color. Select seperate color for this measurement.` : 'Select color for this measurement.'}
                                    top={-20}
                                    left={20}
                                    size={'small'}
                                    width={200}
                                    height={20}
                                >
                                    <div
                                        className='measurementsidebar-option-color-placeholder'
                                        style={{
                                            backgroundColor: measurement?.color,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowColorPicker(true);
                                        }}
                                    ></div>
                                </ButtonHoverInfo>
                            </div>
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Name
                        </div>

                        <div>
                            <AutoResizeTextInput
                                type='text'
                                className='measurementsidebar-option-input'
                                value={measurementName}
                                onChange={(e) => setMeasurementName(e.target.value || '')}
                                onBlur={() => handlePolygonTransform({
                                    ...measurement,
                                    name: measurementName,
                                })}
                            />
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-header'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Group
                            </div>

                            {measurement?.group ?
                                <div>
                                    <DefaultButton
                                        text={'Ungroup'}
                                        handleClick={() => groupMeasurement({
                                            id: measurement.id,
                                            group: null,
                                        })}
                                        size={'small'}
                                    />
                                </div>
                                : null}
                        </div>

                        {groups && Object.values(groups).length > 0
                            ? <GroupMenu
                                handleClick={(groupID) => groupMeasurement({
                                    id: measurement.id,
                                    group: groupID,
                                })}
                                measurement={measurement}
                            />
                            : <div>
                                <div className='measurementsidebar-option-modal-section-info'>
                                    Create a group to organize measurements
                                </div>

                                <div>
                                    <input
                                        type='text'
                                        className='measurementsidebar-option-input'
                                        placeholder='New group name...'
                                        value={newGroupName}
                                        onChange={(e) => setNewGroupName(e.target.value)}
                                        onBlur={() => {
                                            if (newGroupName) {
                                                createGroup(newGroupName)
                                                setNewGroupName('');
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className='measurementsidebar-option-modal-split-section'>
                        <div className='measurementsidebar-option-modal-section-item'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Angle
                            </div>

                            <div>
                                <input
                                    type='number'
                                    value={measurement?.size ? measurement.size : ''}
                                    className='measurementsidebar-option-input'
                                    onChange={(e) => setMeasurements(prev => ({
                                        ...prev,
                                        [measurement.id]: {
                                            ...prev[measurement.id],
                                            size: e.target.value ? e.target.value : null,
                                        }
                                    }))}
                                    onBlur={() => handlePolygonTransform({
                                        ...measurement,
                                        size: measurement.size ? measurement.size : null,
                                    })}
                                />
                            </div>
                        </div>

                        <div className='measurementsidebar-option-modal-section-item'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Stripe gap
                            </div>

                            <div>
                                <input
                                    type='number'
                                    value={measurement?.gap ? measurement.gap : ''}
                                    className='measurementsidebar-option-input'
                                    min={0}
                                    max={20}
                                    onChange={(e) => setMeasurements(prev => ({
                                        ...prev,
                                        [measurement.id]: {
                                            ...prev[measurement.id],
                                            gap: e.target.value
                                                ? e.target.value <= 0
                                                    ? null
                                                    : e.target.value > 20
                                                        ? 20
                                                        : e.target.value
                                                : null,
                                        }
                                    }))}
                                    onBlur={() => handlePolygonTransform({
                                        ...measurement,
                                        gap: measurement.gap ? measurement.gap : null,
                                    })}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Details
                        </div>

                        <div className='measurementsidebar-option-modal-item'>
                            <div>
                                Measured area
                            </div>
                            <div>
                                {(measurement.area * (pages[measurement.page]?.scale ** 2)).toFixed(1)} ft<sup>2</sup>
                            </div>
                        </div>

                        {measurement?.depth !== null && measurement?.depth !== 0
                            ? <div className='measurementsidebar-option-modal-item'>
                                <div>
                                    Calculated volume
                                </div>
                                <div>
                                    {(measurement.area * (pages[measurement.page]?.scale ** 2) * measurement?.depth).toFixed(1)} ft<sup>3</sup>
                                </div>
                            </div>
                            : null}

                        {measurement?.pitch !== null && measurement?.pitch !== 0
                            ? <div className='measurementsidebar-option-modal-item'>
                                <div>
                                    Pitched area
                                </div>
                                <div>
                                    {(measurement.area / (Math.cos(Math.atan(measurement.pitch / 12))) * (pages[measurement.page]?.scale ** 2)).toFixed(1)} ft<sup>2</sup>
                                </div>
                            </div>
                            : null}

                        {measurement?.depth !== null && measurement?.depth !== 0 && measurement?.pitch !== null && measurement?.pitch !== 0
                            ? <div className='measurementsidebar-option-modal-item'>
                                <div>
                                    Pitched volume
                                </div>
                                <div>
                                    {(measurement.area / (Math.cos(Math.atan(measurement.pitch / 12))) * (pages[measurement.page]?.scale ** 2) * measurement?.depth).toFixed(1)} ft<sup>3</sup>
                                </div>
                            </div>
                            : null}
                    </div>

                    <div className='measurementsidebar-option-modal-split-section'>
                        <div className='measurementsidebar-option-modal-section-item'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Depth (ft)
                            </div>

                            <div>
                                <input
                                    type='number'
                                    className='measurementsidebar-option-modal-section-input'
                                    value={measurement.depth ? measurement.depth : ''}
                                    onChange={(e) => setMeasurements(prev => ({
                                        ...prev,
                                        [measurement.id]: {
                                            ...prev[measurement.id],
                                            depth: e.target.value ? e.target.value : null,
                                        }
                                    }))}
                                    onBlur={(e) => handlePolygonTransform(measurement)}
                                />
                            </div>
                        </div>

                        <div className='measurementsidebar-option-modal-section-item'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Pitch (<sup>in</sup>/<sub>12 in</sub>)
                            </div>

                            <div>
                                <input
                                    type='number'
                                    className='measurementsidebar-option-modal-section-input'
                                    value={measurement.pitch ? measurement.pitch : ''}
                                    onChange={(e) => setMeasurements(prev => ({
                                        ...prev,
                                        [measurement.id]: {
                                            ...prev[measurement.id],
                                            pitch: e.target.value ? e.target.value : null,
                                        }
                                    }))}
                                    onBlur={(e) => handlePolygonTransform(measurement)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer
                style={{
                    padding: '2%',
                }}
            >
                <DeleteButton
                    text={'Delete'}
                    handleClick={() => {
                        setSelectedMeasurements([measurement.id]);
                        setCurrentMeasurement(measurement.id);
                        setShowDeleteMeasurementModal(true);
                    }}
                />

                <DefaultButton
                    text={'Close'}
                    handleClick={() => setShowModal(false)}
                />
            </Modal.Footer>
        </Modal>
    )
}