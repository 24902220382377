import { useContext } from 'react';
import { TakeoffContext } from '../helper/Context';
import '../styles/SelectedTotals.css';
import { IconWindowMinimize, IconX } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';
import { UOM, UOM_Display, UOM_Measured_Default, calculateValue } from '../helper/UnitConversions';

export default function SelectedCalculatedTotals({ }) {
    const {
        project,
        takeoffSettings,
        measurements,
        selectedMeasurements,
        pages,
        showSelectedTotal, setShowSelectedTotal,
        currentMeasurement,
        selectedCalculatedTotals,
    } = useContext(TakeoffContext);

    let right = takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'right' && document.getElementById('measurementsidebar')
        ? document.getElementById('measurementsidebar').offsetWidth * 1.01 + 5
        : takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'right' && document.getElementById('pagessidebar')
            ? document.getElementById('pagessidebar').offsetWidth * 1.01 + 5
            : takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'right' && document.getElementById('aisidebar')
                ? document.getElementById('aisidebar').offsetWidth * 1.01 + 5
                : '5px'

    return (
        <div
            className="selectedtotals-container"
            style={{
                right: right,
                top: (currentMeasurement || selectedMeasurements.length > 1) && '85px',
            }}
        >
            <div className="selectedtotals-header">
                <div className="selectedtotals-title">
                    Calculated selection
                </div>
            </div>

            <div className="selectedtotals-content">
                {selectedCalculatedTotals
                    ? <>
                        <div className="selectedtotals-content-row">
                            <div className="selectedtotals-content-row-title">
                                ea
                            </div>
                            <div className="selectedtotals-content-row-value">
                                {selectedCalculatedTotals?.count}
                            </div>
                        </div>
                        <div className="selectedtotals-content-row">
                            <div className="selectedtotals-content-row-title">
                                ft
                            </div>
                            <div className="selectedtotals-content-row-value">
                                {selectedCalculatedTotals?.length?.toFixed(2)}
                            </div>
                        </div>
                        <div className="selectedtotals-content-row">
                            <div className="selectedtotals-content-row-title">
                                ft²
                            </div>
                            <div className="selectedtotals-content-row-value">
                                {selectedCalculatedTotals?.area?.toFixed(2)}
                            </div>
                        </div>
                    </>
                    : <div>
                        Waiting for selection
                    </div>
                }
            </div>
        </div>
    )
}