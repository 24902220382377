import { useState, useEffect, useMemo, useContext, createContext, createElement, useRef } from 'react';
import { selectAuth } from '../redux/slices/authSlice';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  all,
  always,
  andThen,
  any,
  append,
  complement,
  compose,
  concat,
  equals,
  filter,
  flatten,
  fromPairs,
  head,
  ifElse,
  insert,
  isEmpty,
  isNil,
  juxt,
  keys,
  length,
  lensPath,
  lensProp,
  map,
  mergeAll,
  mergeDeepLeft,
  mergeDeepRight,
  negate,
  not,
  omit,
  over,
  pickBy,
  pipe,
  prop,
  reduce,
  reject,
  set,
  sort,
  sum,
  tap,
  toPairs,
  uniq,
  view,
  xprod,
} from 'ramda';
import { Link, useParams } from 'react-router-dom';
import { API_ROUTE } from '../index';
import { ColumnHeader, ColumnWrapper, ColumnListElement, IconElement, ToolbarButton, MenuLineItem, EstimateHintedTextField, NestedMenu, EstimateCheckboxField } from './components/styledcomponents';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  applyToDndNodeSubtree,
  assembleDndTreeFromTreeData,
  axiosCurry,
  dfs,
  fullGenerateJSPDFTable,
  generateSortFunction,
  getAllBetweenElements,
  getDepthFromDepthDict,
  getDepths,
  getDirectionalizedSortFunction,
  getHighestAncestor,
  getIndexOfNodeIDFromAssembledDndTree,
  getPDFCellValueFromGetters,
  fullGetRoutedField,
  getTableCellValueFromGetters,
  fullHandleTableExport,
  mapIndexed,
  populateCanHaveChildren,
  restColumns,
  safeReject,
  sortNodeChildren,
  getFieldType,
  getColumnHeader,
  getIsNumeric,
  fullGetRoutedFieldChildren,
  numToStr2Places,
  getColumnDescription,
  fullGetIsNodeMatching,
  fullGetAncestors,
  fullGetIsShown,
  DEFAULT_COLOR,
  getTableExportElement,
  getBlobFromURL,
  defaultItemColumns,
} from './Utils2';

import {
  fullGetIsCalculated,
  fullGetCalculationsObject,
  fullGetChildren,
  fullGetDependencies,
  fullGetShouldUseCalculatedValue,
  fullGetIsApplicable,
  fullGetIsEditable,
  fullGetValue,
  fullGetPrimaryValue,
  getIsColumnApplicableToGroup,
} from './Calculations2';

import {
  nodeIDLens,
  getTreeNodeLens,
  getTreeNodeNameLens,
  getTreeNodeChildrenLens,
  getTreeNodeTypeLens,
  getTreeNodeGroupLens,
  getTreeNodeIndexLens,
  getTreeNodeQuantity1Lens,
  getTreeNodeQuantity2Lens,
  getTreeNodeDescriptionLens,
  getTreeNodePageTitleLens,
  getTreeNodeColorLens,
  getTreeNodeUOMLens,
  getTreeNodeUseGroupColorLens,
} from './Lenses';

import TextField from '../components/TextField';
import ContextMenuItem from '../components/ContextMenuItem';
import { SortableTree } from './tree/SortableTree';
import { FolderTreeItemWrapper } from './tree/ui/folder/FolderTreeItemWrapper';
import Modal from 'react-bootstrap/Modal';
import {
  IconArrowsMinimize,
  IconArrowUp,
  IconCheck,
  IconCheckbox,
  IconCirclePlus,
  IconCubePlus,
  IconDatabase,
  IconDatabaseEdit,
  IconDatabasePlus,
  IconDots,
  IconEdit,
  IconFileExport,
  IconFolder,
  IconFolderOff,
  IconFolderPlus,
  IconHexagonPlus,
  IconHistory,
  IconSearch,
  IconSquare,
  IconTablePlus,
  IconTrashX,
  IconX,
} from '@tabler/icons-react';
import PDFExport from '../pdfexport/PDFExport';
import Popup from 'reactjs-popup';
import { IconArrowsMaximize } from '@tabler/icons-react';
import DefaultButton from '../components/DefaultButton';
import { Resizable } from 'react-resizable';
import EstimateNavDropDown from '../components/EstimateNavDropDown';
import EstimateProjectsNavDropDown from '../components/EstimateProjectsNavDropDown';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import VendorQuotes from './vendorquotes/VendorQuotes';
import DatabasePopup from './database/DatabasePopup';
import DeleteButton from '../components/DeleteButton';
import ColorField from './components/ColorField';
import Skeleton from 'react-loading-skeleton';
import { UOM, UOM_Display } from '../takeoff/helper/UnitConversions';
import { Item, Menu, Submenu, useContextMenu } from 'react-contexify';
import DatabasePopupCreator from './database/DatabasePopupCreator';
import ProjectDetailsModal from './components/ProjectDetailsModal';

import './styles/EstimateRevamp.css';
import { formDataFromObj, reverseView } from '../utilities/utilities';
import { generateDummyID } from '../files/Utilities';
import { dataLens, getExportFileFileKeyLens, getExportFileNameLens } from '../files/Files';

const EstimateTableSortableTreeContext = createContext();

const TreeComponent = React.forwardRef((props, ref) => {
  const nodeID = props.item.id;
  const { getTreeComponentChildren, getIsNodeSelected, getShowDragHandle } = useContext(EstimateTableSortableTreeContext);
  return (
    <FolderTreeItemWrapper manualDrag={true} showDragHandle={getShowDragHandle(nodeID)} {...props} ref={ref} className={getIsNodeSelected(nodeID) ? 'bg-blue-200' : ''}>
      {getTreeComponentChildren(nodeID)}
    </FolderTreeItemWrapper>
  );
});

const responseDataLens = lensPath(['data']); //for a response
const projectDataLens = lensPath(['projectData']);
const topLevelIDsLens = compose(projectDataLens, lensPath(['top_level_ids']));
const treeDataLens = compose(projectDataLens, lensPath(['tree_data']));
const contractorLens = compose(projectDataLens, lensPath(['contractor']));
const newlyAddedIDLens = compose(projectDataLens, lensPath(['newly_added_id']));
const projectLens = compose(projectDataLens, lensPath(['project']));
const projectIDLens = compose(projectLens, lensPath(['id']));
// const projectLatitudeLens = compose(projectLens, lensPath(['lat']));
// const projectLongitudeLens = compose(projectLens, lensPath(['lng']));
const projectTitleLens = compose(projectLens, lensPath(['title']));
const contractorSettingsLens = lensPath(['contractorSettings']);
const columnSettingsDictLens = compose(contractorSettingsLens, lensPath(['column_settings_dict']));
const expansionSettingsDictLens = compose(contractorSettingsLens, lensPath(['expansion_settings_dict']));
const sortSettingsDictLens = compose(contractorSettingsLens, lensPath(['sort_settings_dict']));
const sortingAttributeLens = compose(sortSettingsDictLens, lensPath(['sort_column']));
const isAscendingLens = compose(sortSettingsDictLens, lensPath(['is_ascending']));
const selectionLens = lensPath(['selection']);
const firstIDAnchorLens = compose(selectionLens, lensPath(['firstIDAnchor']));
const secondIDAnchorLens = compose(selectionLens, lensPath(['secondIDAnchor']));
const selectedIDsLens = compose(selectionLens, lensPath(['selectedIDs']));
const hoveredIDLens = compose(selectionLens, lensPath(['hoveredID']));
const secondaryViewsLens = lensPath(['secondaryViews']);
const isColumnSettingsOpenLens = compose(secondaryViewsLens, lensPath(['isColumnSettingsOpen']));
const groupEditLens = compose(secondaryViewsLens, lensPath(['groupEdit']));
const isTotalsFloaterExpandedLens = compose(secondaryViewsLens, lensPath(['isTotalsFloaterExpanded']));
const isGroupEditOpenLens = compose(groupEditLens, lensPath(['isGroupEditOpen']));
const groupEditSelectedColumnLens = compose(groupEditLens, lensPath(['selectedColumn']));
const groupEditValueLens = compose(groupEditLens, lensPath(['groupEditValue']));
const onebuildNodeIDLens = compose(secondaryViewsLens, lensPath(['onebuildNodeID']));
const isPDFExportOpenLens = compose(secondaryViewsLens, lensPath(['isPDFExportOpen']));
const isPDFSelectedExportOpenLens = compose(secondaryViewsLens, lensPath(['isPDFSelectedExportOpen']));
const isAddItemFromDatabaseOpenLens = compose(secondaryViewsLens, lensPath(['isAddItemFromDatabaseOpen']));
const isMultiImportFromDatabaseOpenLens = compose(secondaryViewsLens, lensPath(['isMultiImportFromDatabaseOpen']));
const columnContextMenuLens = compose(secondaryViewsLens, lensPath(['columnContextMenu']));
const isDeleteConfirmationOpenLens = compose(secondaryViewsLens, lensPath(['isDeleteConfirmationOpen']));
const isExportMenuOpenLens = compose(secondaryViewsLens, lensPath(['isExportMenuOpen']));
const isResizingLens = lensPath(['isResizing']);
const isLoadingLens = lensPath(['isLoading']);
const searchTextLens = lensPath(['searchText']);
const showProjectDetailsLens = lensPath(['showProjectDetails']);

const getNodeLens = (nodeID) => compose(treeDataLens, getTreeNodeLens(nodeID));
const getNodeNameLens = (nodeID) => compose(treeDataLens, getTreeNodeNameLens(nodeID));
const getNodeDescriptionLens = (nodeID) => compose(treeDataLens, getTreeNodeDescriptionLens(nodeID));
const getNodePageTitleLens = (nodeID) => compose(treeDataLens, getTreeNodePageTitleLens(nodeID));
const getNodeChildrenLens = (nodeID) => compose(treeDataLens, getTreeNodeChildrenLens(nodeID));
const getNodeGroupLens = (nodeID) => compose(treeDataLens, getTreeNodeGroupLens(nodeID));
const getNodeIndexLens = (nodeID) => compose(treeDataLens, getTreeNodeIndexLens(nodeID));
const getNodeTypeLens = (nodeID) => compose(treeDataLens, getTreeNodeTypeLens(nodeID));
const getNodeUOMLens = (nodeID) => compose(treeDataLens, getTreeNodeUOMLens(nodeID));
const getNodeQuantity1Lens = (nodeID) => compose(treeDataLens, getTreeNodeQuantity1Lens(nodeID));
const getNodeQuantity2Lens = (nodeID) => compose(treeDataLens, getTreeNodeQuantity2Lens(nodeID));
const getNodeUseGroupColorLens = (nodeID) => compose(treeDataLens, getTreeNodeUseGroupColorLens(nodeID));
const getNodeColorLens = (nodeID) => compose(treeDataLens, getTreeNodeColorLens(nodeID));

const isColumnShownItemLens = lensPath(['is_shown_item']);
const isColumnShownGroupLens = lensPath(['is_shown_group']);
const getColumnIsShownItemLens = (column) => compose(columnSettingsDictLens, lensPath([column]), isColumnShownItemLens);
const getColumnIsShownGroupLens = (column) => compose(columnSettingsDictLens, lensPath([column]), isColumnShownGroupLens);

const getColumnWidthLens = (column) => compose(columnSettingsDictLens, lensPath([column, 'width']));
const getRowIsExpandedLens = (nodeID) => compose(expansionSettingsDictLens, lensPath([nodeID, 'is_expanded']));

const getIsExpandedFromState = (state) => (nodeID) => view(getRowIsExpandedLens(nodeID))(state) || false;
const getHighestSelectedAncestorsFromState = (state) =>
  pipe(view(selectedIDsLens), getHighestAncestor(fullGetChildren(view(treeDataLens)(state))), (ghs) => map(ghs)(view(topLevelIDsLens)(state)), flatten)(state);
const fullGetIsColumnVisible = (state) => (column) =>
  column == 'name' || view(getColumnIsShownItemLens(column))(state) || (getIsColumnApplicableToGroup(column) && view(getColumnIsShownGroupLens(column))(state));

const defaultGroupColumns = new Set(['description', 'subtotal', 'total', 'cost']);

const NODE_CONTEXT_MENU_ID = 'node-context-menu';
const COLUMN_CONTEXT_MENU_ID = 'column-context-menu';

const initialState = {
  projectData: {
    company: {},
    project: {},
    top_level_ids: [],
    tree_data: {},
  },
  contractorSettings: {
    column_settings_dict: ['name', ...restColumns].reduce(
      (acc, key) => mergeDeepLeft(acc)({ [key]: { is_shown_item: defaultItemColumns.has(key), is_shown_group: defaultGroupColumns.has(key), width: 200 } }),
      {}
    ),
    expansion_settings_dict: {},
    sort_settings: { sort_column: 'index', is_ascending: true },
  },
  selection: {
    firstIDAnchor: null,
    secondIDAnchor: null,
    selectedIDs: new Set(),
    hoveredID: null,
    newlyAddedID: null,
  },
  secondaryViews: {
    isColumnSettingsOpen: false,
    groupEdit: { isGroupEditOpen: false, selectedColumn: null, groupEditValue: null },
    onebuildNodeID: null,
    isPDFExportOpen: false,
    isPDFSelectedExportOpen: false,
    isDeleteConfirmationOpen: false,
    isExportMenuOpen: false,
    isTotalsFloaterExpanded: false,
    isAddItemFromDatabaseOpen: false,
    isMultiImportFromDatabaseOpen: false,
    columnContextMenu: null,
  },
  isResizing: false,
  isLoading: true,
  searchText: '',
};

const cycleSortingAttribute = (newSortingAttribute) => (state) => {
  const sortingAttribute = view(sortingAttributeLens)(state);
  const isAscending = view(isAscendingLens)(state);
  if (sortingAttribute == newSortingAttribute && isAscending) return set(isAscendingLens, false)(state);
  else if (sortingAttribute == newSortingAttribute && !isAscending) return pipe(set(isAscendingLens, true), set(sortingAttributeLens)('index'))(state);
  return pipe(set(sortingAttributeLens)(newSortingAttribute), set(isAscendingLens)(true))(state);
};
const fixIndices = (state) =>
  pipe(
    view(topLevelIDsLens),
    map(dfs(fullGetChildren(view(treeDataLens)(state)))),
    flatten,
    mapIndexed((id, index) => [getNodeIndexLens(id), index]),
    reduce((acc, [lens, index]) => set(lens)(index)(acc))(state)
  )(state);
const setNodeValue = (column) => (nodeID) => set(compose(treeDataLens, lensPath([nodeID, column])));
const multiSetNodeValue = (editList) => (state) => reduce((acc, [column, nodeID, value]) => setNodeValue(column)(nodeID)(value)(acc))(state)(editList);
const deleteNodeFromAllChildren = (nodeID) => (state) => pipe(view(treeDataLens), keys, map(getNodeChildrenLens), reduce((acc, lens) => over(lens)(safeReject(equals(nodeID)))(acc))(state))(state);
const deleteNodeFromTopLevelIDs = (nodeID) => over(topLevelIDsLens)(reject(equals(nodeID)));
const deleteNodeFromTreeData = (nodeID) => over(treeDataLens)(omit([nodeID]));
const deleteNode = (nodeID) => pipe(deleteNodeFromTreeData(nodeID), deleteNodeFromAllChildren(nodeID), deleteNodeFromTopLevelIDs(nodeID));
const deleteNodeFromExpansionSettings = (nodeID) => over(expansionSettingsDictLens)(omit([nodeID]));
const insertNode = (insertionIndex) => (parentNodeID) => (node) => {
  const nodeID = view(nodeIDLens)(node);
  const parentChildrenLens = isNil(parentNodeID) ? topLevelIDsLens : getNodeChildrenLens(parentNodeID);
  const nodeGroupLens = getNodeGroupLens(nodeID);
  return pipe(set(getNodeLens(nodeID))(node), over(parentChildrenLens)(insert(insertionIndex)(nodeID)), set(nodeGroupLens)(parentNodeID));
};
const reparentNode = (insertionIndex) => (parentNodeID) => (nodeID) => (state) =>
  pipe(deleteNode(nodeID), insertNode(insertionIndex)(parentNodeID)(view(getNodeLens(nodeID))(state)), fixIndices)(state);
const deselectAll = pipe(set(selectedIDsLens)(new Set()), set(firstIDAnchorLens)(null), set(secondIDAnchorLens)(null));
const selectFiltered = (filterFn) => (state) => {
  const itemIDs = pipe(view(treeDataLens), keys, filter(filterFn))(state);
  return pipe(set(selectedIDsLens)(new Set(itemIDs)), set(firstIDAnchorLens)(null), set(secondIDAnchorLens)(null))(state);
};
const addNodeToSelection = (nodeID) => over(selectedIDsLens)((selectedIDs) => new Set([...selectedIDs, nodeID]));
const removeNodeFromSelection = (nodeID) => over(selectedIDsLens)(pipe(Array.from, reject(equals(nodeID)), (arr) => new Set(arr)));
const setBothAnchors = (nodeID) => pipe(set(firstIDAnchorLens)(nodeID), set(secondIDAnchorLens)(nodeID));
const singleClickSelect = (nodeID) => pipe(deselectAll, setBothAnchors(nodeID), addNodeToSelection(nodeID));
const metaClickSelect = (nodeID) => (state) =>
  view(selectedIDsLens)(state).has(nodeID) ? pipe(removeNodeFromSelection(nodeID), setBothAnchors(null))(state) : pipe(addNodeToSelection(nodeID), setBothAnchors(nodeID))(state);
const shiftClickSelect = (sortedVisibleIDs) => (nodeID) => (state) => {
  const indexOfNode = sortedVisibleIDs.indexOf(nodeID);
  if (indexOfNode == -1) return state;
  const selectedIDs = view(selectedIDsLens)(state);
  const firstAnchor = view(firstIDAnchorLens)(state);
  const secondAnchor = view(secondIDAnchorLens)(state);
  if (isNil(firstAnchor) || isNil(secondAnchor)) return pipe(set(firstIDAnchorLens)(nodeID), set(secondIDAnchorLens)(nodeID), addNodeToSelection(nodeID))(state);
  const toRemove = getAllBetweenElements(firstAnchor)(secondAnchor)(sortedVisibleIDs);
  const toAdd = getAllBetweenElements(firstAnchor)(nodeID)(sortedVisibleIDs);
  const removed = toRemove.reduce((accSet, elem) => {
    accSet.delete(elem);
    return accSet;
  }, selectedIDs);
  const added = new Set([...removed, ...toAdd]);
  return pipe(set(selectedIDsLens)(added), set(secondIDAnchorLens)(nodeID))(state);
};
const generateSelectFunction = (sortedVisibleIDs) => (nodeID) => (e) => {
  if (e.metaKey || e.ctrlKey) return metaClickSelect(nodeID);
  else if (e.shiftKey) return shiftClickSelect(sortedVisibleIDs)(nodeID);
  else return singleClickSelect(nodeID);
};

const generateCheckboxSelectFunction = (sortedVisibleIDs) => (nodeID) => (e) => {
  if (e.shiftKey) return shiftClickSelect(sortedVisibleIDs)(nodeID);
  return metaClickSelect(nodeID);
};

const contextMenuSelect = (nodeID) => (state) => {
  if (isNil(nodeID) || view(selectedIDsLens)(state).has(nodeID)) return state;
  return pipe(deselectAll, set(selectedIDsLens)(new Set([nodeID])))(state);
};
const setNodeIsExpanded = (isExpanded) => (nodeID) => set(getRowIsExpandedLens(nodeID))(isExpanded);
const clearSelectedGroupEditColumn = set(groupEditSelectedColumnLens)(null);
const clearGroupEditValue = set(groupEditValueLens)(null);
const closeGroupEdit = pipe(clearSelectedGroupEditColumn, clearGroupEditValue, set(isGroupEditOpenLens)(false));
const resizeColumn = (column) => (width) => set(getColumnWidthLens(column))(width);
const toggleItemColumn = (column) => over(getColumnIsShownItemLens(column))(not);
const toggleGroupColumn = (column) => over(getColumnIsShownGroupLens(column))(not);

const getDoesMatchParentColor = (state) => (nodeID) => {
  const groupID = view(getNodeGroupLens(nodeID))(state);
  if (isNil(groupID)) return view(getNodeColorLens(nodeID))(state) == DEFAULT_COLOR;
  return view(getNodeColorLens(groupID))(state) == view(getNodeColorLens(nodeID))(state);
};

const getColorEdits = (state) =>
  pipe(
    view(treeDataLens),
    keys,
    filter(pipe(getNodeUseGroupColorLens, reverseView(state))),
    filter(pipe(getDoesMatchParentColor(state), not)),
    map((nodeID) => [nodeID, view(getNodeGroupLens(nodeID))(state)]),
    map(([nodeID, groupID]) => [nodeID, groupID ? view(getNodeColorLens(groupID))(state) : DEFAULT_COLOR]),
    map(([nodeID, color]) => ['color', nodeID, color])
  )(state);

const getFullEditList = (state) => (getIsEditable) => (editList) => {
  const a = filter(([column, nodeID, _]) => getIsEditable(column)(nodeID))(editList);
  const b = reduce((acc, [column, nodeID, value]) => {
    if (column == 'color' && getIsEditable('use_group_color')(nodeID)) {
      return concat(acc)([
        [column, nodeID, value],
        ['use_group_color', nodeID, false],
      ]);
    } else {
      return append([column, nodeID, value])(acc);
    }
  }, [])(a);
  const c = reduce((acc, [column, nodeID, value]) => {
    if (column == 'uom') {
      return concat(acc)([
        [column, nodeID, value],
        ['quantity1', nodeID, null],
        ['quantity2', nodeID, null],
      ]);
    } else {
      return append([column, nodeID, value])(acc);
    }
  }, [])(b);
  const newState = multiSetNodeValue(c)(state);
  const colorEdits = getColorEdits(newState);
  return concat(c)(colorEdits);
};
const getNodeChangesDict = reduce((acc, [column, nodeID, value]) => mergeDeepRight(acc)({ [nodeID]: { [column]: value } }))({});

const EstimateTable = () => {
  const auth = useSelector(selectAuth);
  const userID = auth.user.id;
  const contractorID = auth.contractor.id;
  const { projectUUID } = useParams();
  const [state, setState] = useState(initialState);
  const [showProjectDetails, setShowProjectDetails] = useState(false);
  const newlyAddedIDRef = useRef(null);
  const { show: showNodeContextMenu } = useContextMenu({
    id: NODE_CONTEXT_MENU_ID,
  });
  const { show: showColumnContextMenu } = useContextMenu({ id: COLUMN_CONTEXT_MENU_ID });

  const nodeIDs = Object.keys(view(treeDataLens)(state));

  const headers = {
    Authorization: `Token ${auth.token}`,
    'Content-Type': 'application/json',
  };

  const estimateTableAPI = useMemo(() => axiosCurry(`${API_ROUTE}/api/estimate-table/${projectUUID}/`)(headers), [projectUUID, auth.token]);
  const estimateTableContractorSettingsAPI = useMemo(
    () => axiosCurry(`${API_ROUTE}/api/estimate-table-contractor-settings/${view(projectIDLens)(state)}/${contractorID}/`)(headers),
    [contractorID, view(projectIDLens)(state)]
  );
  const filesAPI = useMemo(() => axiosCurry(`${API_ROUTE}/api/files-api/${projectUUID}/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' }), [API_ROUTE, auth.token]);

  const uploadBlobToS3 = (filename) => (blob) => {
    const presignedHeaders = andThen(view(responseDataLens))(axiosCurry(`${API_ROUTE}/api/aws-presigned-headers-api/`)({ Authorization: `Token ${auth.token}` })('post')({ filename }));
    const urlPromise = andThen(prop('url'))(presignedHeaders);
    const fieldsPromise = andThen(prop('fields'))(presignedHeaders);
    const keyPromise = andThen(prop('key'))(fieldsPromise);
    const file = new File([blob], filename);
    const formDataPromise = pipe(
      andThen((fields) => ({ ...fields, file })),
      andThen(formDataFromObj)
    )(fieldsPromise);
    return pipe(
      (promises) => Promise.all(promises),
      andThen(([url, formData]) => axios.post(url, formData)),
      andThen(always(keyPromise))
    )([urlPromise, formDataPromise]);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key == 'Escape') setState(deselectAll);
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (isNil(view(newlyAddedIDLens)(state))) return;
    newlyAddedIDRef.current?.scrollIntoView();
    setState(singleClickSelect(view(newlyAddedIDLens)(state)));
  }, [view(newlyAddedIDLens)(state)]);

  useEffect(() => {
    pipe(
      estimateTableAPI('get'),
      andThen(view(responseDataLens)),
      andThen((projectData) => setState(pipe(set(projectDataLens)(projectData), set(isLoadingLens)(false))))
    )();
  }, []);

  useEffect(() => {
    const projectID = view(projectIDLens)(state);
    if (contractorID && projectID) estimateTableContractorSettingsAPI('get')().then(pipe(view(responseDataLens), (newSettings) => over(contractorSettingsLens)(mergeDeepLeft(newSettings)), setState));
  }, [contractorID, view(projectIDLens)(state)]);

  const getIsColumnVisible = fullGetIsColumnVisible(state);
  const visibleColumns = restColumns.filter(getIsColumnVisible);

  const getNodeType = (nodeID) => view(getNodeTypeLens(nodeID))(state);
  const getNodeName = (nodeID) => view(getNodeNameLens(nodeID))(state);
  const getNodeDescription = (nodeID) => view(getNodeDescriptionLens(nodeID))(state);
  const getNodePageTitle = (nodeID) => view(getNodePageTitleLens(nodeID))(state);
  const getNodeGroup = (nodeID) => view(getNodeGroupLens(nodeID))(state);
  const getNodeUOM = (nodeID) => view(getNodeUOMLens(nodeID))(state);
  const getAncestors = fullGetAncestors(getNodeGroup);
  const getChildren = fullGetChildren(view(treeDataLens)(state));
  const getIsColumnShownItem = (column) => column == 'name' || view(getColumnIsShownItemLens(column))(state);
  const getIsColumnShownGroup = (column) => column == 'name' || view(getColumnIsShownGroupLens(column))(state);

  const matchedSearchNodeIDSet = useMemo(() => {
    const searchText = view(searchTextLens)(state);
    if (searchText == '') return new Set(nodeIDs);
    const filteredNodes = nodeIDs.filter(
      pipe(juxt([fullGetIsNodeMatching(getNodeName)(searchText), fullGetIsNodeMatching(getNodeDescription)(searchText), fullGetIsNodeMatching(getNodePageTitle)(searchText)]), any(equals(true)))
    );
    const descendants = pipe(map(dfs(getChildren)), reduce(concat)([]))(filteredNodes);
    const ancestors = pipe(map(getAncestors), reduce(concat)([]))(filteredNodes);
    return new Set(descendants.concat(ancestors));
  }, [view(treeDataLens)(state), view(searchTextLens)(state)]);

  const getValue = fullGetValue(view(treeDataLens)(state));
  const getIsApplicable = fullGetIsApplicable(getValue)(getNodeType);
  const getIsCalculated = fullGetIsCalculated(getNodeType)(getIsApplicable);
  const getDependencies = fullGetDependencies(getIsCalculated)(getChildren)(getNodeType);
  const getIsEditable = fullGetIsEditable(getIsApplicable);
  const getShouldUseCalculatedValue = fullGetShouldUseCalculatedValue(getIsEditable)(getIsCalculated)(getValue)(() => () => undefined);
  const getIsShown = fullGetIsShown(getIsColumnShownItem)(getIsColumnShownGroup)(getNodeType);
  const getRoutedField = fullGetRoutedField(getIsShown)(getValue)(getIsCalculated)(getIsApplicable)(getIsEditable)(getFieldType);

  const toCalculate = !view(isResizingLens)(state) && xprod(restColumns)(nodeIDs);
  const calculations =
    !view(isResizingLens)(state) &&
    reduce((acc, [col, id]) => fullGetCalculationsObject(acc)(getIsCalculated)(getDependencies)(getNodeType)(getChildren)(getShouldUseCalculatedValue)(getValue)(col)(id))({})(toCalculate);
  const getCalculatedValue = (column) => (nodeID) => calculations[column]?.[nodeID];
  const getPrimaryValue = fullGetPrimaryValue(getIsEditable)(getIsCalculated)(getValue)(getCalculatedValue);
  const getRoutedFieldChildren = fullGetRoutedFieldChildren(getIsApplicable)(getValue)(getPrimaryValue);

  const sortFunction = getDirectionalizedSortFunction(view(isAscendingLens)(state))(generateSortFunction(getPrimaryValue)(view(sortingAttributeLens)(state)));
  const sortedTopLevelIDs = sort(sortFunction)(view(topLevelIDsLens)(state));
  const sortedTreeData = pipe(
    view(treeDataLens),
    toPairs,
    map(([id, node]) => [id, sortNodeChildren(sortFunction)(node)]),
    fromPairs
  )(state);

  const getIsExpanded = getIsExpandedFromState(state);
  const getVisibleChildren = (nodeID) => ifElse(getIsExpanded, fullGetChildren(sortedTreeData), () => [])(nodeID).filter((nodeID) => matchedSearchNodeIDSet.has(nodeID)); //this returns sorted children given node id
  const getDepth = pipe(map(getDepths(getVisibleChildren)(0)), mergeAll, getDepthFromDepthDict)(sortedTopLevelIDs);
  const getIsGroup = pipe(getNodeType, equals('group'));
  const getIsAssembly = pipe(getNodeType, equals('assembly'));
  const getIsExpandable = (nodeID) => getIsGroup(nodeID) || getIsAssembly(nodeID);
  const getIsItem = pipe(getNodeType, equals('item'));
  const getIsAssemblyEntry = pipe(getNodeType, equals('assembly_entry'));
  const selectAllItems = selectFiltered((nodeID) => !getIsGroup(nodeID) && !getIsAssemblyEntry(nodeID));
  const getNodeValue = (column) => (nodeID) => view(compose(getNodeLens(nodeID), lensProp(column)))(state);
  const getIsNodeSelected = (nodeID) => view(selectedIDsLens)(state).has(nodeID);
  const getPDFCellValue = getPDFCellValueFromGetters(getIsShown)(getDepth)(getNodeType)(getIsApplicable)(getPrimaryValue);
  const getTableCellValue = getTableCellValueFromGetters(getIsShown)(getIsNumeric)(getIsCalculated)(getDepth)(getNodeType)(getIsApplicable)(getPrimaryValue);
  const getNodeColor = (nodeID) => (getIsAssemblyEntry(nodeID) ? getNodeColor(getNodeGroup(nodeID)) : view(getNodeColorLens(nodeID))(state));
  const getNodeUOMMeasured = getPrimaryValue('uom_measured');

  const projectPrice = !view(isResizingLens)(state) && pipe(view(topLevelIDsLens), map(getPrimaryValue('total')), filter(isFinite), sum)(state);
  const projectCost =
    !view(isResizingLens)(state) &&
    pipe(view(topLevelIDsLens), map(dfs(getChildren)), flatten, reject(getIsGroup), reject(getIsAssemblyEntry), map(getCalculatedValue('cost')), filter(isFinite), sum)(state);
  const projectProfit = !view(isResizingLens)(state) && projectPrice - projectCost;
  const projectProfitMargin = !view(isResizingLens)(state) && (projectProfit / projectPrice) * 100;
  const selectedUOMSet = useMemo(
    () =>
      pipe(
        view(selectedIDsLens),
        Array.from,
        reject(getIsExpandable),
        reject(getIsItem),
        reject(getIsAssemblyEntry),
        map((id) => getPrimaryValue('uom')(id) || getPrimaryValue('uom_measured')(id)),
        (uomList) => new Set(uomList)
      )(state),
    [view(selectedIDsLens)(state)]
  );
  // const hasCollapsed = useMemo(() => {
  //   return String(JSON.stringify(view(expansionSettingsDictLens)(state))).includes(`"is_expanded":false`);
  // }, [view(expansionSettingsDictLens)(state)]);
  // const hasExpanded = useMemo(() => {
  //   return String(JSON.stringify(view(expansionSettingsDictLens)(state))).includes(`"is_expanded":true`);
  // }, [view(expansionSettingsDictLens)(state)]);

  const sortedVisibleIDs = pipe(
    map(dfs(getVisibleChildren)),
    flatten,
    filter((nodeID) => matchedSearchNodeIDSet.has(nodeID))
  )(sortedTopLevelIDs);

  const assembledDnDTree = pipe(
    map(assembleDndTreeFromTreeData(getVisibleChildren)),
    map(applyToDndNodeSubtree(populateCanHaveChildren(getIsGroup)))
  )(sortedTopLevelIDs.filter((nodeID) => matchedSearchNodeIDSet.has(nodeID)));

  const handleTableExport = fullHandleTableExport(getTableCellValue)(['name', ...visibleColumns]);
  const generateJSPDFTable = fullGenerateJSPDFTable(getIsExpandable)(getIsExpanded)(getPDFCellValue)(['name', ...visibleColumns]);

  const handleMultiEditNode = pipe(
    getFullEditList(state)(getIsEditable),
    tap((editList) => setState(multiSetNodeValue(editList))),
    getNodeChangesDict,
    (changes) => estimateTableAPI('patch')({ changes, type: 'edit_nodes' })
  );

  const handleEditNode = (column) => (nodeID) => (value) => {
    const editList = [[column, nodeID, value]];
    handleMultiEditNode(editList);
  };

  const handleToggleExpansion = (nodeID) => {
    if (!getIsExpandable(nodeID)) return;
    pipe(over(getRowIsExpandedLens(nodeID))(not), tap(setState), view(expansionSettingsDictLens), (newGroupSettings) =>
      estimateTableContractorSettingsAPI('patch')({ type: 'update_expansion_settings', expansion_settings_dict: newGroupSettings })
    )(state);
  };

  const handleToggleItemColumn = (column) =>
    pipe(toggleItemColumn(column), tap(setState), view(columnSettingsDictLens), (newColumnSettings) =>
      estimateTableContractorSettingsAPI('patch')({ type: 'update_column_settings', column_settings_dict: newColumnSettings })
    )(state);

  const handleToggleGroupColumn = (column) =>
    pipe(toggleGroupColumn(column), tap(setState), view(columnSettingsDictLens), (newColumnSettings) =>
      estimateTableContractorSettingsAPI('patch')({ type: 'update_column_settings', column_settings_dict: newColumnSettings })
    )(state);

  const handleModifyAllExpansion = (isExpanded) => {
    const expandables = pipe(view(treeDataLens), keys, filter(getIsExpandable))(state);
    const newState = reduce((acc, nodeID) => setNodeIsExpanded(isExpanded)(nodeID)(acc))(state)(expandables);
    pipe(tap(setState), view(expansionSettingsDictLens), (newExpansionSettings) =>
      estimateTableContractorSettingsAPI('patch')({ type: 'update_expansion_settings', expansion_settings_dict: newExpansionSettings })
    )(newState);
  };

  const handleDeleteSelection = () => {
    pipe(
      getHighestSelectedAncestorsFromState,
      tap((to_be_deleted_list) => estimateTableAPI('patch')({ type: 'delete_nodes', to_be_deleted_list })),
      reduce((acc, nodeID) => deleteNode(nodeID)(acc))(state),
      fixIndices,
      deselectAll,
      set(isDeleteConfirmationOpenLens)(false),
      tap(setState),
      (newState) => map(assembleDndTreeFromTreeData(fullGetChildren(view(treeDataLens)(newState))))(view(topLevelIDsLens)(newState)),
      (newTree) => estimateTableAPI('patch')({ type: 'restructure_tree', tree: newTree })
    )(state);
  };

  const handleReparentSelection = (parentNodeID) => (insertionIndex) => (toBeReparented) => {
    const reparentedState = pipe(reduce((acc, nodeID) => reparentNode(insertionIndex)(parentNodeID)(nodeID)(acc))(state), fixIndices)(toBeReparented);
    const colorChanges = getColorEdits(reparentedState);
    const newState = multiSetNodeValue(colorChanges)(reparentedState);
    const newTree = map(assembleDndTreeFromTreeData(fullGetChildren(view(treeDataLens)(newState))))(view(topLevelIDsLens)(newState));
    setState(newState);
    estimateTableAPI('patch')({ type: 'restructure_tree', tree: newTree });
    estimateTableAPI('patch')({ type: 'edit_nodes', changes: getNodeChangesDict(colorChanges) });
  };

  const getTreeComponentChildren = (nodeID) => {
    const type = getNodeType(nodeID);
    return (
      <ColumnListElement className={`gap-2 !items-start grow ${getIsNodeSelected(nodeID) ? 'bg-blue-200' : ''}`} key={nodeID} onContextMenu={handleNodeContextMenuClick(nodeID)}>
        <div className='w-0 h-0' ref={view(newlyAddedIDLens)(state) == nodeID ? newlyAddedIDRef : null}></div>
        <div className='flex flex-row items-center w-8 h-full'>
          <IconElement
            value={type}
            isExpanded={getIsExpanded(nodeID)}
            onClick={() => (type == 'group' || type == 'assembly') && handleToggleExpansion(nodeID)}
            className={type == 'group' ? 'cursor-pointer' : ''}
            style={{ color: getNodeColor(nodeID) }}
          />
        </div>
        <EstimateHintedTextField
          className={'h-9 w-full'}
          value={getNodeValue('name')(nodeID)}
          onClick={pipe(generateSelectFunction(sortedVisibleIDs)(nodeID), setState)}
          onBlur={handleEditNode('name')(nodeID)}
        >
          {type != 'group' && (
            <button onClick={() => setState(set(onebuildNodeIDLens)(nodeID))} className='flex flex-row items-center h-full'>
              <IconDatabase size={20} />
            </button>
          )}
        </EstimateHintedTextField>
      </ColumnListElement>
    );
  };

  const getGroupEditColumns = () => {
    if (!view(isGroupEditOpenLens)(state)) return [];
    const selectedIDsList = Array.from(view(selectedIDsLens)(state));
    const shouldIncludeUOM = pipe(map(getNodeUOMMeasured), uniq, length, equals(1))(selectedIDsList);
    const shouldIncludeQ1Q2 = shouldIncludeUOM && pipe(map(getNodeUOM), uniq, length, equals(1))(selectedIDsList);
    const commonEditableColumns = filter(pipe((column) => map(getIsEditable(column))(selectedIDsList), all(equals(true))))(['name', ...visibleColumns]);
    // const commonEditableColumns = filter((column) => pipe(selectedIDsList, map(getIsEditable(column)), all(equals(true)))(state))(['name', ...visibleColumns]);
    return commonEditableColumns.filter((column) => (!(column == 'uom') || shouldIncludeUOM) && (!(column == 'quantity1' || column == 'quantity2') || shouldIncludeQ1Q2));
  };

  const handleAddNode = pipe(
    estimateTableAPI('post'),
    andThen(view(responseDataLens)),
    andThen((projectData) => set(projectDataLens)(projectData)(state)),
    andThen(tap(setState)),
    andThen((newState) => map(assembleDndTreeFromTreeData(fullGetChildren(view(treeDataLens)(newState))))(view(topLevelIDsLens)(newState))),
    andThen((newTree) => estimateTableAPI('patch')({ type: 'restructure_tree', tree: newTree }))
  );

  const handleResizeColumnEnd = (column) => (width) =>
    pipe(resizeColumn(column)(width), set(isResizingLens)(false), tap(setState), (newState) =>
      estimateTableContractorSettingsAPI('patch')({ type: 'update_column_settings', column_settings_dict: view(columnSettingsDictLens)(newState) })
    )(state);

  const handleNodeContextMenuClick = (nodeID) => (e) => {
    e.preventDefault();
    setState(pipe(contextMenuSelect(nodeID)));
    showNodeContextMenu({ event: e });
  };

  const handleColumnContextMenuClick = (column) => (e) => {
    e.preventDefault();
    setState(set(columnContextMenuLens)(column));
    showColumnContextMenu({ event: e });
  };

  return (
    <div className='flex flex-col w-screen h-screen overflow-hidden'>
      <div className='database-navbar'>
        <div className='database-navbar-items'>
          {/*<div id='database-navbar-logo' className='database-navbar-logo-container'>
            <a href='/dashboard'>
              <img src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png' alt='logo' className='database-navbar-logo' id='database-navbar-logo' />
            </a>
          </div>*/}
          <EstimateNavDropDown projectUUID={projectUUID} />
          <div
            className='flex items-stretch select-none overflow-clip whitespace-nowrap hover:bg-[#EEEEEE] max-w-64'
            onClick={() => {
              setShowProjectDetails(true);
            }}
          >
            {/*<Link to={`/project/${projectUUID}`} className='flex items-center justify-center w-full p-2 no-underline'>
              {view(projectTitleLens)(state)}
        </Link>*/}

            <div className='flex items-center justify-center w-full p-2 hover:cursor-pointer'>{view(projectTitleLens)(state)}</div>
          </div>

          {showProjectDetails && view(projectLens)(state) && (
            <ProjectDetailsModal
              projectIn={view(projectLens)(state)}
              setGlobalProject={(newProject) => setState((state) => set(projectLens)(newProject)(state))}
              showProjectDetails={showProjectDetails}
              setShowProjectDetails={setShowProjectDetails}
            />
          )}

          <EstimateProjectsNavDropDown projectUUID={projectUUID} />
        </div>
        <div className='flex items-center gap-2'>
          <button>
            <IconSearch size={20} />
          </button>
          <div className='flex flex-row items-center group has-[:focus]:!bg-blue-alice h-8'>
            <TextField
              value={view(searchTextLens)(state)}
              onBlur={pipe(set(searchTextLens), setState)}
              placeholder='Search...'
              className='h-full pl-1 border-l border-solid rounded-l-sm outline-none text-blue-bobyard w-72 border-y border-gray-revell bg-inherit group-hover:!border-blue-bobyard'
            />
            <button
              tabIndex='-1'
              className='w-8 h-full flex items-center justify-center border-r border-y rounded-r-sm cursor-pointer hover:!bg-gray-revell group-hover:!border-blue-bobyard'
              onClick={() => setState(set(searchTextLens)(''))}
            >
              <IconX size={20} />
            </button>
          </div>
        </div>
        <div className='database-navbar-items-group'>
          <div className='database-navbar-items'>
            <ToolbarButton id='scroll-up-button' onClick={() => document.getElementById('scroll-top')?.scrollIntoView()}>
              <IconArrowUp size={20} />
            </ToolbarButton>
            <Tooltip anchorSelect='#scroll-up-button' delayShow={500}>
              Scroll to top
            </Tooltip>
            <ToolbarButton id='select-all-button' onClick={() => setState(selectAllItems)}>
              <IconCheckbox size={20} />
            </ToolbarButton>
            <Tooltip anchorSelect='#select-all-button' delayShow={500}>
              Select all Items
            </Tooltip>
            <ToolbarButton id='expand-all-button' onClick={() => handleModifyAllExpansion(true)}>
              <IconArrowsMaximize size={20} />
            </ToolbarButton>
            <Tooltip anchorSelect='#expand-all-button' delayShow={500}>
              Expand all groups
            </Tooltip>
            <ToolbarButton id='collapse-all-button' onClick={() => handleModifyAllExpansion(false)}>
              <IconArrowsMinimize size={20} />
            </ToolbarButton>
            <Tooltip anchorSelect='#collapse-all-button' delayShow={500}>
              Collapse all groups
            </Tooltip>
          </div>
          <div className='database-navbar-items'>
            <ToolbarButton onClick={() => handleAddNode({ type: 'add_group', parent_id: null, name: 'New Folder' })} id='add-group-button'>
              <IconFolderPlus size={20} />
            </ToolbarButton>
            <Tooltip anchorSelect='#add-group-button' delayShow={500}>
              Add a group
            </Tooltip>
            <ToolbarButton onClick={() => handleAddNode({ type: 'add_item', parent_id: null, name: 'New Item', author: contractorID })} id='add-item-button'>
              <IconCirclePlus size={20} />
            </ToolbarButton>
            <Tooltip anchorSelect='#add-item-button' delayShow={500}>
              Add an item
            </Tooltip>
            <ToolbarButton onClick={() => handleAddNode({ type: 'add_assembly', parent_id: null, name: 'New Assembly', author: contractorID })} id='add-assembly-button'>
              <IconCubePlus size={20} />
            </ToolbarButton>
            <Tooltip anchorSelect='#add-assembly-button' delayShow={500}>
              Add an assembly
            </Tooltip>
          </div>
          <div className='database-navbar-items'>
            <VendorQuotes items={view(treeDataLens)(state)} projectUUID={projectUUID} handleSetMaterialCost={handleEditNode('material_cost')} handleSetVendorName={handleEditNode('material_vendor')} />
            <Tooltip anchorSelect='#database-navbar-columns-setting' delayShow={500}>
              Open Vendor Quotes
            </Tooltip>
            <ToolbarButton onClick={() => setState(set(isAddItemFromDatabaseOpenLens)(true))} id='database-creator'>
              <IconDatabasePlus size={20} />
            </ToolbarButton>
            <Tooltip anchorSelect='#database-creator' delayShow={500}>
              Create item(s) from database
            </Tooltip>
            <DatabasePopupCreator
              show={view(isAddItemFromDatabaseOpenLens)(state)}
              onHide={() => setState(set(isAddItemFromDatabaseOpenLens)(false))}
              setItem={(a) =>
                handleAddNode({ type: 'add_from_database', author: contractorID, database_id: a.database_id, properties: a.properties }).then(() => setState(set(isAddItemFromDatabaseOpenLens)(false)))
              }
            />
            <ToolbarButton onClick={() => setState(set(isMultiImportFromDatabaseOpenLens)(true))} id='database-multi' disabled={view(selectedIDsLens)(state).size == 0}>
              <IconDatabaseEdit size={20} />
            </ToolbarButton>
            <Tooltip anchorSelect='#database-multi' delayShow={500}>
              Copy database item to selected measurements
            </Tooltip>
            <DatabasePopup
              show={view(isMultiImportFromDatabaseOpenLens)(state)}
              onHide={() => setState(set(isMultiImportFromDatabaseOpenLens)(false))}
              setItem={pipe(
                toPairs,
                xprod(Array.from(view(selectedIDsLens)(state))),
                map(([nodeID, [column, value]]) => [column, nodeID, value]),
                handleMultiEditNode
              )}
            />
            <Popup
              trigger={
                <button
                  className={
                    view(selectedIDsLens)(state).size
                      ? 'flex items-center justify-center w-10 h-10 gap-2 text-sm font-light hover:text-blue-bobyard hover:bg-gray-revell'
                      : 'flex items-center justify-center w-10 h-10 gap-2 text-sm font-light text-gray-darkish hover:text-blue-bobyard hover:bg-gray-revell hover:cursor-not-allowed'
                  }
                  disabled={!view(selectedIDsLens)(state).size}
                  id={'group-edit-button'}
                >
                  <Tooltip anchorSelect='#group-edit-button' delayShow={500}>
                    Edit a field for all selected rows
                  </Tooltip>
                  <IconEdit size={20} color={view(selectedIDsLens)(state).size ? 'black' : 'silver'} />
                </button>
              }
              open={view(isGroupEditOpenLens)(state)}
              onOpen={() => setState(set(isGroupEditOpenLens)(true))}
              onClose={() => setState(closeGroupEdit)}
              position='bottom right'
              closerOnDocumentClick
              mouseLeaveDelay={300}
              mouseEnterDelay={0}
              contentStyle={{
                width: '500px',
              }}
            >
              <div className='flex flex-row w-full gap-4 text-sm font-light text-gray-darkish h-[450px] p-2.5'>
                <div className='w-64 min-w-0 overflow-scroll'>
                  {view(isGroupEditOpenLens)(state) &&
                    pipe(
                      map((column) => (
                        <MenuLineItem
                          className={column == view(groupEditSelectedColumnLens)(state) ? 'bg-blue-alice text-blue-bobyard' : ''}
                          onClick={() => setState(pipe(clearGroupEditValue, set(groupEditSelectedColumnLens)(column)))}
                          key={column}
                        >
                          {getColumnHeader(column)}
                        </MenuLineItem>
                      ))
                    )(getGroupEditColumns())}
                </div>
                <div className='flex flex-col min-w-0 grow'>
                  <div className='font-medium mb-2.5'>Edit a field for all selected rows</div>
                  {view(isGroupEditOpenLens)(state) &&
                    view(groupEditSelectedColumnLens)(state) != null &&
                    pipe(view(groupEditSelectedColumnLens), (column) => {
                      const dummyID = pipe(view(selectedIDsLens), Array.from, head)(state);
                      const field = column != 'color' ? getRoutedField(column)(dummyID) : ColorField;
                      const value = view(groupEditValueLens)(state);
                      const children = getRoutedFieldChildren(column)(dummyID);
                      return createElement(field, {
                        value,
                        onBlur: (value) => setState(set(groupEditValueLens)(value)),
                        className: 'my-4 !h-8 w-full border !border-gray-revell',
                        children,
                      });
                    })(state)}
                  <div className='flex flex-row items-end justify-end w-full min-h-0 gap-2 grow'>
                    <DefaultButton
                      handleClick={() => {
                        const column = view(groupEditSelectedColumnLens)(state);
                        const nodeIDs = Array.from(view(selectedIDsLens)(state));
                        const value = view(groupEditValueLens)(state);
                        const editList = map((nodeID) => [column, nodeID, value])(nodeIDs);
                        handleMultiEditNode(editList);
                      }}
                    >
                      Save
                    </DefaultButton>
                    <DefaultButton handleClick={() => setState(closeGroupEdit)}>Close</DefaultButton>
                  </div>
                </div>
              </div>
            </Popup>
            <Popup
              trigger={
                <button // why can't a component be used here????????????
                  className='flex items-center justify-center w-10 h-10 gap-2 text-sm font-light hover:text-blue-bobyard hover:bg-gray-revell '
                  id='column-settings-button'
                >
                  <IconTablePlus size={20} />
                  <Tooltip anchorSelect='#column-settings-button' delayShow={500}>
                    Toggle columns
                  </Tooltip>
                </button>
              }
              open={view(isColumnSettingsOpenLens)(state)}
              onOpen={() => setState(set(isColumnSettingsOpenLens)(true))}
              onClose={() => setState(set(isColumnSettingsOpenLens)(true))}
              position='bottom right'
              closeOnDocumentClick
              mouseLeaveDelay={300}
              mouseEnterDelay={0}
              contentStyle={{
                width: '500px',
              }}
            >
              <div className='p-2.5'>
                <div className='flex flex-row items-center justify-between'>
                  <div className='text-lg font-medium text-gray-darkish'>Columns</div>
                  <div className='flex flex-row justify-right items-center gap-2.5'>
                    <span
                      className='flex items-center px-2.5 py-1 text-sm font-light text-gray-darkish border border-solid rounded outline-none hover:bg-gray-whitesmoke cursor-pointer'
                      onClick={() =>
                        pipe(
                          reduce((acc, column) => pipe(set(getColumnIsShownItemLens(column))(true), set(getColumnIsShownGroupLens(column))(true))(acc))(state),
                          tap(setState),
                          view(columnSettingsDictLens),
                          (newColumnSettings) => estimateTableContractorSettingsAPI('patch')({ type: 'update_column_settings', column_settings_dict: newColumnSettings })
                        )(restColumns)
                      }
                    >
                      Include all
                    </span>
                    <span
                      className='flex items-center px-2.5 py-1 text-sm font-light text-gray-darkish border border-solid rounded outline-none hover:bg-gray-whitesmoke cursor-pointer'
                      onClick={() =>
                        pipe(
                          reduce((acc, column) => pipe(set(getColumnIsShownItemLens(column))(false), set(getColumnIsShownGroupLens(column))(false))(acc))(state),
                          tap(setState),
                          view(columnSettingsDictLens),
                          (newColumnSettings) => estimateTableContractorSettingsAPI('patch')({ type: 'update_column_settings', column_settings_dict: newColumnSettings })
                        )(restColumns)
                      }
                    >
                      Exclude all
                    </span>
                  </div>
                </div>
                <div className='estimate-table-column-settings-header-container'>
                  <div className='estimate-table-column-settings-header'>
                    <div className='estimate-table-column-settings-header-item'>Items</div>
                    <div className='estimate-table-column-settings-header-item'>|</div>
                    <div className='estimate-table-column-settings-header-item'>Groups</div>
                  </div>
                </div>
                <div className='flex flex-col items-center overflow-scroll max-h-96'>
                  {restColumns.map((column) => (
                    <div key={column} className='estimate-table-column-settings-item-container'>
                      <div className='estimate-table-column-settings'>
                        <div className='estimate-table-column-settings-header-item'>
                          <EstimateCheckboxField value={getIsColumnShownItem(column)} onClick={() => handleToggleItemColumn(column)} />
                        </div>
                        <div className='estimate-table-column-settings-header-item'>|</div>
                        <div className='estimate-table-column-settings-header-item'>
                          <EstimateCheckboxField disabled={!getIsColumnApplicableToGroup(column)} value={getIsColumnShownGroup(column)} onClick={() => handleToggleGroupColumn(column)} />
                        </div>
                      </div>
                      <span>{getColumnHeader(column)}</span>
                    </div>
                  ))}
                </div>
              </div>
            </Popup>
            <Popup
              trigger={
                <button // why can't a component be used here????????????
                  className='flex items-center justify-center w-10 h-10 gap-2 text-sm font-light hover:text-blue-bobyard hover:bg-gray-revell'
                  id='export-button'
                >
                  <IconFileExport size={20} />
                  <Tooltip anchorSelect='#export-button' delayShow={500}>
                    Export
                  </Tooltip>
                </button>
              }
              open={view(isExportMenuOpenLens)(state)}
              onOpen={() => setState(set(isExportMenuOpenLens)(true))}
              onClose={() => setState(set(isExportMenuOpenLens)(false))}
              position='bottom right'
              closerOnDocumentClick
              mouseLeaveDelay={300}
              mouseEnterDelay={0}
              contentStyle={{
                width: '300px',
              }}
            >
              <div className='flex flex-col w-full'>
                <MenuLineItem
                  onClick={() => {
                    const filename = `${new Date().toISOString().split('T')[0]} - ${view(projectTitleLens)(state)}.csv`;
                    const dummyID = generateDummyID();
                    pipe(
                      map((id) => map(getTableCellValue(id))(['name', ...visibleColumns])),
                      concat([map(getColumnHeader)(['name', ...visibleColumns])]),
                      getTableExportElement('csv'),
                      tap((e) => e.click()),
                      tap((e) => e.remove()),
                      prop('href'),
                      getBlobFromURL('text/csv'),
                      andThen(uploadBlobToS3(filename)),
                      andThen((key) => pipe(set(getExportFileFileKeyLens(dummyID))(key), set(getExportFileNameLens(dummyID))(filename))({})),
                      andThen(view(dataLens)),
                      andThen(filesAPI('patch'))
                    )(sortedVisibleIDs);
                    setState(set(isExportMenuOpenLens)(false));
                  }}
                >
                  Export to CSV
                </MenuLineItem>
                <MenuLineItem
                  onClick={() => {
                    const filename = `${new Date().toISOString().split('T')[0]} - ${view(projectTitleLens)(state)}.xlsx`;
                    const dummyID = generateDummyID();
                    pipe(
                      map((id) => map(getTableCellValue(id))(['name', ...visibleColumns])),
                      concat([map(getColumnHeader)(['name', ...visibleColumns])]),
                      getTableExportElement('xlsx'),
                      tap((e) => e.click()),
                      tap((e) => e.remove()),
                      prop('href'),
                      getBlobFromURL('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
                      andThen(uploadBlobToS3(filename)),
                      andThen((key) => pipe(set(getExportFileFileKeyLens(dummyID))(key), set(getExportFileNameLens(dummyID))(filename))({})),
                      andThen(view(dataLens)),
                      andThen(filesAPI('patch'))
                    )(sortedVisibleIDs);
                    setState(set(isExportMenuOpenLens)(false));
                  }}
                >
                  Export to Excel
                </MenuLineItem>
                <MenuLineItem id='export-to-pdf-button' disabled={visibleColumns.length > 12} onClick={() => setState(pipe(set(isPDFExportOpenLens)(true), set(isExportMenuOpenLens)(false)))}>
                  Export to PDF
                  {visibleColumns.length > 12 && (
                    <Tooltip anchorSelect='#export-to-pdf-button' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
                      Export to PDF is disabled when more than 12 columns are shown
                    </Tooltip>
                  )}
                </MenuLineItem>
                <MenuLineItem
                  disabled={view(selectedIDsLens)(state).size == 0}
                  onClick={() => {
                    pipe(
                      getHighestSelectedAncestorsFromState,
                      (ancestorList) => new Set(ancestorList),
                      (ancestorSet) => sortedVisibleIDs.filter((nodeID) => ancestorSet.has(nodeID)),
                      map(dfs(getVisibleChildren)),
                      flatten,
                      (exportIDList) => handleTableExport(exportIDList)('csv')
                    )(state);
                  }}
                >
                  Export selected rows to CSV
                </MenuLineItem>
                <MenuLineItem
                  disabled={view(selectedIDsLens)(state).size == 0}
                  onClick={() => {
                    pipe(
                      getHighestSelectedAncestorsFromState,
                      (ancestorList) => new Set(ancestorList),
                      (ancestorSet) => sortedVisibleIDs.filter((nodeID) => ancestorSet.has(nodeID)),
                      map(dfs(getVisibleChildren)),
                      flatten,
                      (exportIDList) => handleTableExport(exportIDList)('xlsx')
                    )(state);
                  }}
                >
                  Export selected rows to Excel
                </MenuLineItem>
                <MenuLineItem
                  id='export-selected-to-pdf-button'
                  disabled={view(selectedIDsLens)(state).size == 0 || visibleColumns.length > 12}
                  onClick={() => {
                    setState(pipe(set(isPDFSelectedExportOpenLens)(true), set(isExportMenuOpenLens)(false)));
                  }}
                >
                  Export selected rows to PDF
                  {visibleColumns.length > 12 && (
                    <Tooltip anchorSelect='#export-selected-to-pdf-button' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
                      Export to PDF is disabled when more than 12 columns are shown
                    </Tooltip>
                  )}
                </MenuLineItem>
              </div>
            </Popup>
          </div>

          <a href={`/edit-history/${projectUUID}`} target='_blank'>
            <ToolbarButton id='estimate-edit-history'>
              <IconHistory size={20} />
            </ToolbarButton>
          </a>
          <Tooltip anchorSelect='#estimate-edit-history' delayShow={500}>
            Edit history
          </Tooltip>

          <ToolbarButton
            className={view(selectedIDsLens)(state).size ? 'text-red-normal hover:bg-red-light hover:text-red-normal' : 'text-gray-darkish hover:bg-[#EEEEEE] hover:cursor-not-allowed'}
            onClick={() => {
              if (view(selectedIDsLens)(state).size == 0) return;
              setState(set(isDeleteConfirmationOpenLens)(true));
            }}
            id='delete-button'
          >
            <IconTrashX size={20} color={view(selectedIDsLens)(state).size ? 'red' : 'silver'} />
          </ToolbarButton>
          <Tooltip anchorSelect='#delete-button' delayShow={500}>
            Delete selected
          </Tooltip>
          <a href={`/profile`} className='database-navbar-profile-picture-link'>
            <div className='database-navbar-profile-picture-container'>
              {view(contractorLens)(state)?.profile_picture ? (
                <img src={view(contractorLens)(state)?.profile_picture} alt='profile picture' className='database-navbar-profile-picture' />
              ) : view(contractorLens)(state)?.first_name && view(contractorLens)(state)?.last_name ? (
                <div className='database-navbar-profile-picture-initials'>{view(contractorLens)(state)?.first_name[0] + view(contractorLens)(state)?.last_name[0]}</div>
              ) : (
                <img src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/2828447.png'} alt='profile picture' className='database-navbar-profile-picture' />
              )}
            </div>
          </a>
        </div>
      </div>
      <ScrollSync>
        <div
          className='flex flex-row w-full overflow-hidden'
          style={{
            height: 'calc(100vh - 41px)',
          }}
        >
          <ColumnWrapper className='min-w-fit overflow-clip'>
            <ColumnHeader className='h-10'>
              <IconCheck size={20} />
            </ColumnHeader>
            <ScrollSyncPane>
              <div className='flex flex-col h-full min-h-0 pb-40 overflow-scroll bg-[#F8F8F8] no-scrollbar shrink-0'>
                {sortedVisibleIDs.map((nodeID) => {
                  const isSelected = getIsNodeSelected(nodeID);
                  return (
                    <ColumnListElement onClick={pipe(generateCheckboxSelectFunction(sortedVisibleIDs)(nodeID), setState)} className={`px-2 ${isSelected ? 'bg-blue-200' : ''}`}>
                      <EstimateCheckboxField value={isSelected} />
                    </ColumnListElement>
                  );
                })}
                <div className='h-2/5' />
              </div>
            </ScrollSyncPane>
          </ColumnWrapper>
          <ColumnWrapper className='min-w-fit overflow-clip' style={{ width: view(getColumnWidthLens('name'))(state) }}>
            <Resizable
              handle={<div onClick={(e) => e.stopPropagation} className='w-0.5 h-full bg-gray-lightgray cursor-col-resize hover:bg-blue-bobyard'></div>}
              width={view(getColumnWidthLens('name'))(state)}
              height={0}
              minConstraints={[400, 0]}
              maxConstraints={[1000, Infinity]}
              onResizeStart={() => setState(set(isResizingLens)(true))}
              onResize={(e, data) => setState(resizeColumn('name')(data.size.width))}
              onResizeStop={(e, data) => handleResizeColumnEnd('name')(data.size.width)}
            >
              <ColumnHeader
                onClick={() =>
                  pipe(cycleSortingAttribute('name'), tap(setState), view(sortSettingsDictLens), (sortSettingsDict) =>
                    estimateTableContractorSettingsAPI('patch')({ sort_settings_dict: sortSettingsDict, type: 'update_sort_settings' })
                  )(state)
                }
                id='name'
              >
                Name
                {view(sortingAttributeLens)(state) == 'name' && view(isAscendingLens)(state) && ' ▼'}
                {view(sortingAttributeLens)(state) == 'name' && !view(isAscendingLens)(state) && ' ▲'}
                <Tooltip anchorSelect='#name' delayShow={500} className='z-10'>
                  {getColumnDescription('name')}
                </Tooltip>
              </ColumnHeader>
            </Resizable>
            <ScrollSyncPane>
              <div className='flex flex-col h-full min-h-0 pb-40 overflow-scroll bg-[#F8F8F8] border-r border-lightgray'>
                <div className='w-0 h-0' id='scroll-top'></div>
                <EstimateTableSortableTreeContext.Provider value={{ getTreeComponentChildren, getIsNodeSelected, getShowDragHandle: complement(getIsAssemblyEntry) }}>
                  {view(isResizingLens)(state) || view(isLoadingLens)(state) ? (
                    <Skeleton className='m-2 h-96' width={view(getColumnWidthLens('name'))(state) - 10} />
                  ) : (
                    <SortableTree
                      items={assembledDnDTree}
                      onItemsChanged={(newAssembledDndTree, { draggedItem, droppedToParent }) => {
                        const draggedID = draggedItem?.id;
                        if (!draggedID) return;
                        const parentNodeID = droppedToParent ? droppedToParent.id : null;
                        const insertionIndex = getIndexOfNodeIDFromAssembledDndTree(draggedID)(newAssembledDndTree);
                        const toBeReparented = view(selectedIDsLens)(state).has(draggedID) ? getHighestSelectedAncestorsFromState(state) : [draggedID];
                        handleReparentSelection(parentNodeID)(insertionIndex)(toBeReparented);
                      }}
                      TreeItemComponent={TreeComponent}
                      keepGhostInPlace={view(sortingAttributeLens)(state) != 'index'}
                      dragOverlay={<div className='p-2 bg-white rounded shadow'>Dragging {Math.max(view(selectedIDsLens)(state).size, 1)} item(s)</div>}
                    />
                  )}
                </EstimateTableSortableTreeContext.Provider>
                <div className='h-2/5' />
              </div>
            </ScrollSyncPane>
          </ColumnWrapper>
          <div className='flex flex-row w-full h-full min-w-0 overflow-scroll'>
            {visibleColumns.map((column) => (
              <ColumnWrapper className='min-w-0 overflow-clip' key={column} style={{ width: view(getColumnWidthLens(column))(state) }}>
                <Resizable
                  handle={<div onClick={(e) => e.stopPropagation} className='w-0.5 h-full bg-gray-lightgray cursor-col-resize hover:bg-blue-bobyard'></div>}
                  width={view(getColumnWidthLens(column))(state)}
                  height={0}
                  minConstraints={[200, 0]}
                  maxConstraints={[1000, Infinity]}
                  onResizeStart={() => setState(set(isResizingLens)(true))}
                  onResize={(e, data) => {
                    setState(resizeColumn(column)(data.size.width));
                  }}
                  onResizeStop={(e, data) => handleResizeColumnEnd(column)(data.size.width)}
                >
                  <ColumnHeader
                    onClick={() =>
                      pipe(cycleSortingAttribute(column), tap(setState), view(sortSettingsDictLens), (sortSettingsDict) =>
                        estimateTableContractorSettingsAPI('patch')({ sort_settings_dict: sortSettingsDict, type: 'update_sort_settings' })
                      )(state)
                    }
                    id={column}
                    onContextMenu={handleColumnContextMenuClick(column)}
                  >
                    {getColumnHeader(column)}
                    {view(sortingAttributeLens)(state) == column && view(isAscendingLens)(state) && ' ▼'}
                    {view(sortingAttributeLens)(state) == column && !view(isAscendingLens)(state) && ' ▲'}
                    <Tooltip anchorSelect={`#${column}`} delayShow={500} className='z-10'>
                      {getColumnDescription(column)}
                    </Tooltip>
                  </ColumnHeader>
                </Resizable>
                <ScrollSyncPane>
                  <div className='flex flex-col h-full min-h-0 pb-40 overflow-scroll no-scrollbar bg-[#F8F8F8]'>
                    {view(isResizingLens)(state) || view(isLoadingLens)(state) ? (
                      <Skeleton className='m-2 h-96' width={view(getColumnWidthLens(column))(state) - 10} />
                    ) : (
                      sortedVisibleIDs.map((nodeID) => {
                        const isEditable = getIsEditable(column)(nodeID);
                        const isCalculated = getIsCalculated(column)(nodeID);
                        const fieldComponent = getRoutedField(column)(nodeID);
                        const handleSelect = pipe(generateSelectFunction(sortedVisibleIDs)(nodeID), setState);
                        const fieldComponentProps = {
                          value: isCalculated && !isEditable ? getCalculatedValue(column)(nodeID) : getValue(column)(nodeID),
                          onBlur: handleEditNode(column)(nodeID),
                          placeholder: isCalculated ? getCalculatedValue(column)(nodeID) : null,
                          children: getRoutedFieldChildren(column)(nodeID),
                        };
                        return (
                          <ColumnListElement
                            className={`${getIsNodeSelected(nodeID) ? 'bg-blue-200' : ''} ${view(hoveredIDLens)(state) == nodeID ? 'bg-gray-whitesmoke' : ''}`}
                            key={nodeID}
                            onClick={handleSelect}
                            onContextMenu={handleNodeContextMenuClick(nodeID)}
                          >
                            {createElement(fieldComponent, fieldComponentProps)}
                          </ColumnListElement>
                        );
                      })
                    )}
                    <div className='h-2/5' />
                  </div>
                </ScrollSyncPane>
              </ColumnWrapper>
            ))}
          </div>
        </div>
      </ScrollSync>
      <DatabasePopup
        show={!isNil(view(onebuildNodeIDLens)(state))}
        onHide={() => {
          setState(set(onebuildNodeIDLens)(null));
        }}
        item={null}
        setItem={pipe(
          toPairs,
          xprod([view(onebuildNodeIDLens)(state)]),
          map(([nodeID, [column, value]]) => [column, nodeID, value]),
          handleMultiEditNode
        )}
      />
      <Modal
        show={view(isPDFExportOpenLens)(state)}
        onHide={() => {
          setState(set(isPDFExportOpenLens)(false));
        }}
        size='lg'
      >
        <Modal.Header>
          <div className='estimates-pdf-export-header'>
            <div className='estimates-pdf-export-header-title'>PDF Export</div>
            <div className='estimates-pdf-export-close-button' onClick={() => setState(set(isPDFExportOpenLens)(false))}>
              <IconX size={20} stroke={1} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='flex flex-col gap-2'>
          {view(isPDFExportOpenLens)(state) && (
            <PDFExport
              projectID={view(projectIDLens)(state)}
              pdfTableArrayBuffer={pipe(
                generateJSPDFTable,
                (doc) => {
                  const pageWidth = doc.internal.pageSize.getWidth();
                  doc.text(pageWidth - 10, doc.autoTable.previous.finalY + 10, `Total Price: $${projectPrice.toFixed(2)}`, 'right');
                  return doc;
                },
                (doc) => doc.output('arraybuffer')
              )(sortedVisibleIDs)}
            />
          )}
        </Modal.Body>
      </Modal>
      {/* Selected rows pdf export */}
      <Modal
        show={view(isPDFSelectedExportOpenLens)(state)}
        onHide={() => {
          setState(set(isPDFSelectedExportOpenLens)(false));
        }}
        size='lg'
      >
        <Modal.Header>
          <div className='estimates-pdf-export-header'>
            <div className='estimates-pdf-export-header-title'>PDF Export for selected rows</div>
            <div className='estimates-pdf-export-close-button' onClick={() => setState(set(isPDFSelectedExportOpenLens)(false))}>
              <IconX size={20} stroke={1} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='flex flex-col gap-2'>
          {view(isPDFSelectedExportOpenLens)(state) && (
            <PDFExport
              projectID={view(projectIDLens)(state)}
              pdfTableArrayBuffer={pipe(
                getHighestSelectedAncestorsFromState,
                (ancestorList) => new Set(ancestorList),
                (ancestorSet) => sortedVisibleIDs.filter((nodeID) => ancestorSet.has(nodeID)),
                map(dfs(getVisibleChildren)),
                flatten,
                generateJSPDFTable,
                (doc) => {
                  const pageWidth = doc.internal.pageSize.getWidth();
                  doc.text(pageWidth - 10, doc.autoTable.previous.finalY + 10, `Total Price: $${projectPrice.toFixed(2)}`, 'right');
                  return doc;
                },
                (doc) => doc.output('arraybuffer')
              )(state)}
            />
          )}
        </Modal.Body>
      </Modal>
      <Modal show={view(isDeleteConfirmationOpenLens)(state)} onHide={() => setState(set(isDeleteConfirmationOpenLens)(false))}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className='flex flex-col gap-2'>Delete {view(selectedIDsLens)(state).size} selected item(s)?</Modal.Body>
        <Modal.Footer>
          <DeleteButton handleClick={() => handleDeleteSelection()}>Delete All</DeleteButton>
          <DefaultButton handleClick={() => setState(set(isDeleteConfirmationOpenLens)(false))}>Cancel</DefaultButton>
        </Modal.Footer>
      </Modal>
      <Modal show={view(isDeleteConfirmationOpenLens)(state)} onHide={() => setState(set(isDeleteConfirmationOpenLens)(false))}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className='flex flex-col gap-2'>Delete {view(selectedIDsLens)(state).size} selected item(s)?</Modal.Body>
        <Modal.Footer>
          <DeleteButton handleClick={() => handleDeleteSelection()}>Delete All</DeleteButton>
          <DefaultButton handleClick={() => setState(set(isDeleteConfirmationOpenLens)(false))}>Cancel</DefaultButton>
        </Modal.Footer>
      </Modal>
      {!view(isResizingLens)(state) && (
        <div
          className='fixed z-10 flex flex-row justify-end gap-8 p-2 overflow-hidden font-normal bg-white rounded bottom-2 right-2 w-fit text-medium'
          style={{ boxShadow: ['1px 1px 3px 1px rgb(0 0 0 / 0.1)', '0 1px 2px -1px rgb(0 0 0 / 0.1)'] }}
        >
          <span>Cost: ${numToStr2Places(projectCost)}</span>
          <span>Profit: ${numToStr2Places(projectProfit)}</span>
          <span>Margin: {numToStr2Places(projectProfitMargin)}%</span>
          <span>Price: ${numToStr2Places(projectPrice)}</span>
        </div>
      )}
      {!view(isResizingLens)(state) && !!view(selectedIDsLens)(state).size && (
        <div
          className='fixed z-20 flex flex-row items-center h-10 max-w-lg font-normal bg-white rounded bottom-2 left-2'
          style={{ boxShadow: ['1px 1px 3px 1px rgb(0 0 0 / 0.1)', '0 1px 2px -1px rgb(0 0 0 / 0.1)'] }}
        >
          <div className='flex flex-row items-center h-full pl-2 pr-1 min-w-fit text-blue-bobyard text-nowrap grow overflow-clip'>{view(selectedIDsLens)(state).size} selected</div>
          {!view(isTotalsFloaterExpandedLens)(state) && (
            <button className='h-full pl-1 pr-2 border-l border-solid border-gray-revell hover:bg-gray-revell' onClick={() => setState(set(isTotalsFloaterExpandedLens)(true))}>
              <IconDots />
            </button>
          )}
          {view(isTotalsFloaterExpandedLens)(state) && (
            <div className='flex flex-row items-center h-full gap-2 px-1 overflow-scroll border-l border-solid border-gray-revell'>
              {UOM.filter((uom) => selectedUOMSet.has(uom)).map((uom) => {
                const total = pipe(
                  view(selectedIDsLens),
                  Array.from,
                  reject(getIsGroup),
                  reject(getIsAssemblyEntry),
                  filter((nodeID) => (getPrimaryValue('uom')(nodeID) || getPrimaryValue('uom_measured')(nodeID)) == uom),
                  map(getPrimaryValue('unit_amount')),
                  filter(isFinite),
                  sum
                )(state);
                return (
                  <div key={uom} className='flex flex-row items-center h-full text-nowrap'>
                    {numToStr2Places(total)} {UOM_Display[uom]}
                  </div>
                );
              })}
            </div>
          )}

          {view(isTotalsFloaterExpandedLens)(state) && (
            <button className='h-full py-2 pl-1 pr-2 border-l border-solid border-gray-revell hover:bg-gray-revell' onClick={() => setState(set(isTotalsFloaterExpandedLens)(false))}>
              <IconX />
            </button>
          )}
        </div>
      )}
      <Menu id={NODE_CONTEXT_MENU_ID} theme='bobyard-light'>
        {view(selectedIDsLens)(state).size == 1 && getIsGroup(view(selectedIDsLens)(state).values().next().value) && (
          <>
            <ContextMenuItem onClick={() => handleAddNode({ type: 'add_group', parent_id: view(selectedIDsLens)(state).values().next().value, name: 'New Folder' })}>
              <IconFolderPlus size={20} stroke={1} />
              Add a group
            </ContextMenuItem>
            <ContextMenuItem onClick={() => handleAddNode({ type: 'add_item', parent_id: view(selectedIDsLens)(state).values().next().value, name: 'New Item', author: contractorID })}>
              <IconCirclePlus size={20} stroke={1} />
              Add an item
            </ContextMenuItem>
            <ContextMenuItem onClick={() => handleAddNode({ type: 'add_assembly', parent_id: view(selectedIDsLens)(state).values().next().value, name: 'New Assembly', author: contractorID })}>
              <IconHexagonPlus size={20} stroke={1} />
              Add an assembly
            </ContextMenuItem>
          </>
        )}
        {view(selectedIDsLens)(state).size == 1 && getIsAssembly(view(selectedIDsLens)(state).values().next().value) && (
          <>
            <ContextMenuItem
              onClick={() => handleAddNode({ type: 'add_assembly_entry', parent_id: view(selectedIDsLens)(state).values().next().value, name: 'New Assembly Entry', author: contractorID })}
            >
              <IconCubePlus size={20} stroke={1} />
              Add an assembly entry
            </ContextMenuItem>
          </>
        )}
        {view(selectedIDsLens)(state).size == 1 &&
          pipe(view(selectedIDsLens), (selectedIDs) => selectedIDs.values().next().value, getNodeGroup)(state) &&
          pipe(view(selectedIDsLens), (selectedIDs) => selectedIDs.values().next().value, complement(getIsAssemblyEntry))(state) && (
            <ContextMenuItem onClick={() => handleReparentSelection(null)(0)([view(selectedIDsLens)(state).values().next().value])}>
              <IconFolderOff size={20} stroke={1} />
              Ungroup
            </ContextMenuItem>
          )}
        {view(selectedIDsLens)(state).size > 0 && pipe(view(selectedIDsLens), Array.from, all(complement(getIsAssemblyEntry)))(state) && (
          <>
            <Submenu
              label={
                <Item closeOnClick={false}>
                  <div className='flex flex-row items-center justify-start w-full gap-1 p-1 rounded cursor-pointer text-gray-darkish'>
                    <IconFolder size={20} stroke={1} />
                    Move
                  </div>
                </Item>
              }
            >
              <div className='overflow-scroll max-h-96'>
                {ifElse(
                  isEmpty,
                  () => <div className='p-2 text-gray-darkish'>No groups to move to</div>,
                  map((nodeID) => (
                    <NestedMenu
                      key={nodeID}
                      nodeID={nodeID}
                      getChildren={pipe(getChildren, reject(getIsNodeSelected), filter(getIsGroup))}
                      getItemElement={(nodeID) => <span>{getValue('name')(nodeID)}</span>}
                      getIsCurrent={(nodeID) => view(selectedIDsLens)(state).size == 1 && getNodeValue('group')(view(selectedIDsLens)(state).values().next().value) == nodeID}
                      handleAction={(nodeID) => handleReparentSelection(nodeID)(0)(Array.from(view(selectedIDsLens)(state)))}
                    />
                  ))
                )(
                  view(topLevelIDsLens)(state)
                    .filter((nodeID) => !getIsNodeSelected(nodeID))
                    .filter(getIsGroup)
                )}
              </div>
            </Submenu>
          </>
        )}
        {view(selectedIDsLens)(state).size > 0 && (
          <ContextMenuItem
            className='hover:!text-red-normal hover:bg-pink-light'
            onClick={() => {
              if (view(selectedIDsLens)(state).size == 0) return;
              setState(set(isDeleteConfirmationOpenLens)(true));
            }}
          >
            <IconTrashX size={20} stroke={1} />
            Delete
          </ContextMenuItem>
        )}
      </Menu>
      <Menu id={COLUMN_CONTEXT_MENU_ID} theme='bobyard-light'>
        {
          <ContextMenuItem onClick={() => handleToggleItemColumn(view(columnContextMenuLens)(state))}>
            <EstimateCheckboxField value={getIsColumnShownItem(view(columnContextMenuLens)(state))} />
            <span className='font-medium'>{pipe(view(columnContextMenuLens), getColumnHeader)(state)}</span> for non-groups
          </ContextMenuItem>
        }
        {getIsColumnApplicableToGroup(view(columnContextMenuLens)(state)) && (
          <ContextMenuItem onClick={() => handleToggleGroupColumn(view(columnContextMenuLens)(state))}>
            <EstimateCheckboxField value={getIsColumnShownGroup(view(columnContextMenuLens)(state))} />
            <span className='font-medium'>{pipe(view(columnContextMenuLens), getColumnHeader)(state)}</span> for groups
          </ContextMenuItem>
        )}
      </Menu>
    </div>
  );
};

export default EstimateTable;
